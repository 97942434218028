.ProseMirror input-scale {
  --input-scale-color-1: rgba(204, 204, 204, 1);
  --input-scale-color-2: rgba(112, 112, 112, 1);
  --input-scale-color-3: rgba(85, 85, 85, 1);
  --input-scale-color-4: rgba(255, 255, 255, 1);
  --input-scale-color-5: rgba(227, 227, 227, 1);

  --input-scale-rounded-style-border-radius: 4px;

  --input-scale-labels-in-row-values-background-color: var(
    --input-scale-color-4
  );
  --input-scale-labels-in-row-values-border-color: var(--input-scale-color-1);
  --input-scale-labels-in-row-values-color: var(--input-scale-color-3);
  --input-scale-labels-in-row-value-border-color: var(--input-scale-color-1);

  --input-scale-dropdown-toggle-background-color: var(--input-scale-color-4);
  --input-scale-dropdown-toggle-border-color: var(--input-scale-color-1);
  --input-scale-dropdown-toggle-color: var(--input-scale-color-3);

  --input-scale-dropdown-values-background-color: var(--input-scale-color-4);
  --input-scale-dropdown-values-border-color: var(--input-scale-color-1);
  --input-scale-dropdown-values-color: var(--input-scale-color-3);
  --input-scale-dropdown-value-not-applicable-border-color: var(
    --input-scale-color-1
  );
  --input-scale-dropdown-value-hover-background-color: var(
    --input-scale-color-5
  );

  --input-scale-listbox-values-background-color: var(--input-scale-color-4);
  --input-scale-listbox-values-border-color: var(--input-scale-color-1);
  --input-scale-listbox-values-color: var(--input-scale-color-3);
  --input-scale-listbox-value-not-applicable-border-color: var(
    --input-scale-color-1
  );
  --input-scale-listbox-value-hover-background-color: var(
    --input-scale-color-5
  );

  --input-scale-bullet-border-color: var(--input-scale-color-2);
}

.ProseMirror input-scale-labels {
  --input-scale-color-1: rgba(204, 204, 204, 1);

  --input-scale-labels-in-row-value-border-color: var(--input-scale-color-1);
  --input-scale-labels-controls-background-color: #fafafd;
  --input-scale-labels-controls-border-color: #c7c7cc;
  --input-scale-labels-controls-active-background-color: #006edb;
  --input-scale-labels-controls-active-border-color: #006edb;
}

.ProseMirror[data-palette-type="light"] input-scale {
  --input-scale-labels-in-row-values-background-color: var(
    --color-scheme-primary-1
  );
  --input-scale-labels-in-row-values-border-color: var(--color-scheme-theme-3);
  --input-scale-labels-in-row-values-color: var(--color-scheme-theme-5);
  --input-scale-labels-in-row-value-border-color: var(--color-scheme-theme-3);

  --input-scale-dropdown-toggle-background-color: var(--color-scheme-primary-1);
  --input-scale-dropdown-toggle-border-color: var(--color-scheme-theme-3);
  --input-scale-dropdown-toggle-color: var(--color-scheme-theme-5);

  --input-scale-dropdown-values-background-color: var(--color-scheme-primary-1);
  --input-scale-dropdown-values-border-color: var(--color-scheme-theme-3);
  --input-scale-dropdown-values-color: var(--color-scheme-theme-5);
  --input-scale-dropdown-value-not-applicable-border-color: var(
    --color-scheme-theme-3
  );
  --input-scale-dropdown-value-hover-background-color: var(
    --color-scheme-theme-2
  );

  --input-scale-listbox-values-background-color: var(--color-scheme-primary-1);
  --input-scale-listbox-values-border-color: var(--color-scheme-theme-3);
  --input-scale-listbox-values-color: var(--color-scheme-theme-5);
  --input-scale-listbox-value-not-applicable-border-color: var(
    --color-scheme-theme-3
  );
  --input-scale-listbox-value-hover-background-color: var(
    --color-scheme-theme-2
  );

  --input-scale-bullet-border-color: var(--color-scheme-theme-4);
}

.ProseMirror[data-palette-type="light"] input-scale-labels {
  --input-scale-labels-in-row-value-border-color: var(--color-scheme-theme-3);
}

.ProseMirror[data-palette-type="dark"] input-scale {
  --input-scale-labels-in-row-values-background-color: var(
    --color-scheme-primary-1
  );
  --input-scale-labels-in-row-values-border-color: var(--color-scheme-theme-3);
  --input-scale-labels-in-row-values-color: var(--color-scheme-theme-1);
  --input-scale-labels-in-row-value-border-color: var(--color-scheme-theme-3);

  --input-scale-dropdown-toggle-background-color: var(--color-scheme-primary-1);
  --input-scale-dropdown-toggle-border-color: var(--color-scheme-theme-3);
  --input-scale-dropdown-toggle-color: var(--color-scheme-theme-1);

  --input-scale-dropdown-values-background-color: var(--color-scheme-primary-1);
  --input-scale-dropdown-values-border-color: var(--color-scheme-theme-3);
  --input-scale-dropdown-values-color: var(--color-scheme-theme-1);
  --input-scale-dropdown-value-not-applicable-border-color: var(
    --color-scheme-theme-3
  );
  --input-scale-dropdown-value-hover-background-color: var(
    --color-scheme-theme-2
  );

  --input-scale-listbox-values-background-color: var(--color-scheme-primary-1);
  --input-scale-listbox-values-border-color: var(--color-scheme-theme-3);
  --input-scale-listbox-values-color: var(--color-scheme-theme-1);
  --input-scale-listbox-value-not-applicable-border-color: var(
    --color-scheme-theme-3
  );
  --input-scale-listbox-value-hover-background-color: var(
    --color-scheme-theme-2
  );

  --input-scale-bullet-border-color: var(--color-scheme-theme-4);
}

.ProseMirror[data-palette-type="dark"] input-scale-labels {
  --input-scale-labels-in-row-value-border-color: var(--color-scheme-theme-3);
}

.ProseMirror input-scale {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
}

.ProseMirror input-scale[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror input-scale[data-alignment="left"] {
  margin-right: auto;
}

.ProseMirror input-scale[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror input-scale input-scale-value .bullet {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--input-scale-bullet-border-color);
  background-color: var(--input-scale-dropdown-values-background-color);
  border-radius: 50%;
}

.ProseMirror input-scale input-scale-value .bullet::selection {
  background-color: transparent !important;
}

.ProseMirror input-scale input-scale-value .content {
  cursor: auto;
}

/* Labels in row */
.ProseMirror input-scale[data-control-type="labels-in-row"] input-scale-values {
  color: var(--input-scale-labels-in-row-values-color);
  background-color: var(--input-scale-labels-in-row-values-background-color);
  border: 1px solid var(--input-scale-labels-in-row-values-border-color);
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.ProseMirror input-scale[data-control-type="labels-in-row"] input-scale-value {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  padding: 8px 5px;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"][data-orientation="top"]
  input-scale-value {
  justify-content: flex-end;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"][data-orientation="top"]
  input-scale-value
  .content {
  order: 0;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"][data-orientation="top"]
  input-scale-value
  .bullet {
  order: 1;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"][data-orientation="bottom"]
  input-scale-value {
  justify-content: flex-start;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"][data-orientation="bottom"]
  input-scale-value
  .content {
  order: 1;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"][data-orientation="bottom"]
  input-scale-value
  .bullet {
  order: 0;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"][data-orientation="boxed"]
  input-scale-value
  .content {
  margin-top: auto;
  margin-bottom: auto;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"][data-orientation="boxed"]
  input-scale-value
  .bullet {
  display: none;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"]
  input-scale-value
  ~ input-scale-value {
  border-left: 1px solid transparent;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"]
  input-scale-value.not-applicable {
  border-left-color: var(--input-scale-labels-in-row-value-border-color);
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"].ProseMirror-focusednode
  input-scale-value
  ~ input-scale-value {
  border-left-color: var(--input-scale-labels-in-row-value-border-color);
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"]
  input-scale-value
  .content {
  text-align: center;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"]
  input-scale-value
  .bullet,
.ProseMirror
  input-scale[data-control-type="labels-in-row"]
  input-scale-value
  .bullet[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"]
  input-scale-value
  .bullet[data-alignment="left"] {
  margin-left: initial;
  margin-right: auto;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"]
  input-scale-value
  .bullet[data-alignment="right"] {
  margin-left: auto;
  margin-right: initial;
}

.ProseMirror
  input-scale[data-control-type="labels-in-row"]
  input-scale-value
  .bullet[data-alignment="justify"] {
  margin-left: initial;
  margin-right: auto;
}

/* Dropdown */
.ProseMirror
  input-scale[data-control-type="dropdown"]
  input-scale-dropdown-toggle {
  display: flex;
  height: 32px;
  border: 1px solid var(--input-scale-dropdown-toggle-border-color);
  background-color: var(--input-scale-dropdown-toggle-background-color);
  color: var(--input-scale-dropdown-toggle-color);
}

.ProseMirror
  input-scale[data-control-type="dropdown"]
  input-scale-dropdown-toggle-text {
  padding: 5px 8px;
  flex: 1 1 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProseMirror
  input-scale[data-control-type="dropdown"]
  input-scale-dropdown-toggle-carret {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.ProseMirror input-scale[data-control-type="dropdown"] .input-container {
  position: relative;
}

.ProseMirror input-scale[data-control-type="dropdown"] input-scale-values {
  color: var(--input-scale-dropdown-values-color);
  background-color: var(--input-scale-dropdown-values-background-color);
  border: 1px solid var(--input-scale-dropdown-values-border-color);
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: absolute;
  z-index: 41;
  left: 0;
  right: 0;
}

.ProseMirror
  input-scale[data-control-type="dropdown"][data-dropdown-position="top"]
  input-scale-values {
  bottom: 36px;
}

.ProseMirror
  input-scale[data-control-type="dropdown"][data-dropdown-position="bottom"]
  input-scale-values {
  top: 36px;
}

.ProseMirror
  input-scale[data-control-type="dropdown"]:not([data-dropdown-opened])
  input-scale-values {
  display: none;
}

.ProseMirror input-scale[data-control-type="dropdown"] input-scale-value {
  padding: 8px;
}

.ProseMirror
  input-scale[data-control-type="dropdown"]
  input-scale-value.not-applicable {
  border-top: 1px solid
    var(--input-scale-dropdown-value-not-applicable-border-color);
}

.ProseMirror input-scale[data-control-type="dropdown"] input-scale-value:hover {
  background-color: var(--input-scale-dropdown-value-hover-background-color);
}

.ProseMirror
  input-scale[data-control-type="dropdown"]
  input-scale-value
  .bullet {
  display: none;
}

.ProseMirror
  input-scale[data-control-type="dropdown"]
  input-scale-value
  .content {
  text-align: left;
}

/* Listbox */
.ProseMirror input-scale[data-control-type="listbox"] input-scale-values {
  color: var(--input-scale-listbox-values-color);
  background-color: var(--input-scale-listbox-values-background-color);
  border: 1px solid var(--input-scale-listbox-values-border-color);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.ProseMirror input-scale[data-control-type="listbox"] input-scale-value {
  padding: 8px;
}

.ProseMirror
  input-scale[data-control-type="listbox"]
  input-scale-value.not-applicable {
  border-top: 1px solid
    var(--input-scale-listbox-value-not-applicable-border-color);
}

.ProseMirror input-scale[data-control-type="listbox"] input-scale-value:hover {
  background-color: var(--input-scale-listbox-value-hover-background-color);
}

.ProseMirror
  input-scale[data-control-type="listbox"]
  input-scale-value
  .bullet {
  display: none;
}

.ProseMirror
  input-scale[data-control-type="listbox"]
  input-scale-value
  .content {
  text-align: left;
}

.ProseMirror[data-input-style="rounded"]
  input-scale[data-control-type="labels-in-row"]
  input-scale-values {
  border-radius: var(--input-scale-rounded-style-border-radius);
}

.ProseMirror[data-input-style="rounded"]
  input-scale[data-control-type="dropdown"]
  input-scale-dropdown-toggle,
.ProseMirror[data-input-style="rounded"]
  input-scale[data-control-type="dropdown"]
  input-scale-values {
  border-radius: var(--input-scale-rounded-style-border-radius);
}

.ProseMirror[data-input-style="rounded"]
  input-scale[data-control-type="listbox"]
  input-scale-values {
  border-radius: var(--input-scale-rounded-style-border-radius);
}

.ProseMirror[data-input-style="simple"]
  input-scale[data-control-type="dropdown"]
  input-scale-dropdown-toggle {
  background: transparent;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.ProseMirror input-scale-labels {
  display: contents;
}

.ProseMirror input-scale-labels.ProseMirror-focusednode::before {
  z-index: 21;
}

.ProseMirror input-scale-labels input-scale-labels-content {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0;
  margin-top: -10px;
  margin-left: -16px;
  width: calc(100% + 32px) !important;
  max-width: calc(100% + 32px) !important;
  height: calc(100% + 20px);
  vertical-align: top;
}

.ProseMirror
  .ProseMirror-input-cell[style*="vertical-align: middle"]
  input-scale-labels
  input-scale-labels-content {
  margin-top: 0px;
}

.ProseMirror
  .ProseMirror-input-cell[style*="vertical-align: bottom"]
  input-scale-labels
  input-scale-labels-content {
  margin-bottom: -10px;
}

@-moz-document url-prefix() {
  .ProseMirror
    .ProseMirror-input-cell[style*="vertical-align: middle"]
    input-scale-labels
    input-scale-labels-content {
    margin-top: -10px;
  }

  .ProseMirror
    .ProseMirror-input-cell[style*="vertical-align: bottom"]
    input-scale-labels
    input-scale-labels-content {
    margin-bottom: 0px;
  }
}

.ProseMirror input-scale-labels input-scale-label {
  position: relative;
  display: table-cell;
  padding: 8px 5px;
  text-align: center;
}

.ProseMirror
  .ProseMirror-input-cell[style*="vertical-align: middle"]
  input-scale-labels
  input-scale-label {
  vertical-align: middle;
}

.ProseMirror
  .ProseMirror-input-cell[style*="vertical-align: bottom"]
  input-scale-labels
  input-scale-label {
  vertical-align: bottom;
}

.ProseMirror input-scale-labels input-scale-label ~ input-scale-label {
  border-left: 1px solid var(--input-scale-labels-in-row-value-border-color);
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"] {
  display: contents;
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  .input-container {
  display: contents;
  height: 100%;
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  .input-container
  input-scale-values {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0;
  margin-top: -10px;
  margin-left: -16px;
  width: calc(100% + 32px) !important;
  max-width: calc(100% + 32px) !important;
  height: calc(100% + 20px);
}

.ProseMirror
  .ProseMirror-input-cell[style*="vertical-align: middle"]
  input-scale[data-control-type="labels-in-row"]
  .input-container
  input-scale-values {
  margin-top: 0px;
}

.ProseMirror
  .ProseMirror-input-cell[style*="vertical-align: bottom"]
  input-scale[data-control-type="labels-in-row"]
  .input-container
  input-scale-values {
  margin-bottom: -10px;
}

@-moz-document url-prefix() {
  .ProseMirror
    .ProseMirror-input-cell[style*="vertical-align: middle"]
    input-scale[data-control-type="labels-in-row"]
    .input-container
    input-scale-values {
    margin-top: -10px;
  }

  .ProseMirror
    .ProseMirror-input-cell[style*="vertical-align: bottom"]
    input-scale[data-control-type="labels-in-row"]
    .input-container
    input-scale-values {
    margin-bottom: 0px;
  }
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  input-scale-values {
  border: none;
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  input-scale-values
  input-scale-value {
  display: table-cell;
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  input-scale-values
  input-scale-value
  .content {
  display: none;
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  input-scale-values
  input-scale-value {
  vertical-align: top;
}

.ProseMirror
  .ProseMirror-input-cell[style*="vertical-align: middle"]
  input-scale[data-control-type="labels-in-row"]
  input-scale-values
  input-scale-value {
  vertical-align: middle;
}

.ProseMirror
  .ProseMirror-input-cell[style*="vertical-align: bottom"]
  input-scale[data-control-type="labels-in-row"]
  input-scale-values
  input-scale-value {
  vertical-align: bottom;
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  input-scale-value
  ~ input-scale-value {
  border-left-color: var(--input-scale-labels-in-row-value-border-color);
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  .validation-messages:not(.hidden) {
  position: absolute;
  z-index: 5;
  background: transparent;
  width: 6px;
  height: 6px;
  top: 1px;
  left: 1px;
  padding: 0;
  border-top: 6px solid var(--custom-grid-input-validation-message-border-color);
  border-left: 0 solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 0 solid transparent;
  --validation-messages-required-color-1: var(
    --custom-grid-input-validation-message-color
  );
  --validation-messages-required-color-2: var(
    --custom-grid-input-validation-message-color
  );
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  .validation-messages:not(.hidden):hover {
  border: none;
  width: auto;
  height: auto;
  padding: 4px 6px;
  background-color: var(
    --custom-grid-input-validation-message-background-color
  );
}

.ProseMirror
  .ProseMirror-input-cell
  input-scale[data-control-type="labels-in-row"]
  .validation-messages:not(.hidden):not(:hover)
  > * {
  display: none;
}

.ProseMirror
  input-scale-labels
  input-scale-label
  .ProseMirror-scale-labels-control {
  position: absolute;
  left: -1px;
  right: -1px;
  top: -17px;
  height: 16px;
  background-color: var(--input-scale-labels-controls-background-color);
  border: 1px solid var(--input-scale-labels-controls-border-color);
  border-bottom: none;
  z-index: 20;
  cursor: pointer;
}

.ProseMirror
  input-scale-labels
  input-scale-label.active
  .ProseMirror-scale-labels-control {
  background-color: var(--input-scale-labels-controls-active-background-color);
  border: 1px solid var(--input-scale-labels-controls-active-border-color);
  z-index: 21;
}

.ProseMirror
  input-scale-labels
  input-scale-label
  .ProseMirror-scale-labels-control {
  display: none;
}

.ProseMirror
  .ProseMirror-focusednode
  input-scale-labels
  input-scale-label
  .ProseMirror-scale-labels-control {
  display: block;
}

.ProseMirror
  tr[data-section="footers"]
  input-scale-labels
  input-scale-label
  .ProseMirror-scale-labels-control {
  display: none;
}

.ProseMirror
  input-scale-labels
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-toggle {
  width: 24px;
  height: 16px;
  position: absolute;
  background-color: #006edb;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -17px;
  right: 0px;
  z-index: 21;
}

.ProseMirror
  input-scale-labels
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-toggle
  .triangle {
  color: #ffffff;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.ProseMirror
  input-scale-labels
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 23;
  padding: 10px 0;
  background: #ffffff;
  border: 1px solid #eeeef1;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  font-size: 0.75rem;
  overflow: auto;
  width: auto;
}

.ProseMirror
  input-scale-labels
  input-scale-label:not(:first-child)
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-content {
  right: 2px;
  top: 2px;
}

.ProseMirror
  input-scale-labels
  input-scale-label:first-child
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-toggle {
  left: 0px;
}

.ProseMirror
  input-scale-labels
  input-scale-label:first-child
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-content {
  left: 2px;
  top: 2px;
}

.ProseMirror
  input-scale-labels
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item {
  font-size: 0.75rem;
  color: #5c6670;
  padding: 7px 10px;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
}

.ProseMirror
  input-scale-labels
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item:disabled {
  opacity: 0.3;
  cursor: default;
}

.ProseMirror
  input-scale-labels
  .ProseMirror-scale-labels-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item:not(:disabled):hover {
  color: #0b37aa;
  background: #f2f8ff;
}

.ProseMirror
  custom-grid
  th
  input-scale.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle,
.ProseMirror
  custom-grid
  td
  input-scale.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle {
  display: none;
}

.ProseMirror[data-selector="block"]
  input-scale[data-control-type="listbox"]
  input-scale-values,
.ProseMirror[data-selector="inline"]
  input-scale[data-control-type="listbox"]
  input-scale-values {
  max-height: none !important;
}

.ProseMirror[data-selector="block"]
  input-scale[data-control-type="dropdown"]
  input-scale-values,
.ProseMirror[data-selector="inline"]
  input-scale[data-control-type="dropdown"]
  input-scale-values {
  display: flex !important;
  max-height: none !important;
  position: initial !important;
  margin-top: 5px !important;
}

.ProseMirror custom-grid td input-scale {
  width: 100% !important;
}
