.ProseMirror .ProseMirror-question-title-binding {
  --question-title-binding-outline: #d209e1;
  outline: 1px solid var(--question-title-binding-outline);
}

.ProseMirror .ProseMirror-question-title-linked {
  --question-title-linked-outline: #006edb;
  outline: 2px dotted var(--question-title-linked-outline);
}

.ProseMirror .ProseMirror-question-title-empty {
  --question-title-placeholder-color: #c7c7cc;
}

.ProseMirror .ProseMirror-question-title-empty::before {
  content: attr(data-placeholder) !important;
  color: var(--question-title-placeholder-color);
}

.ProseMirror custom-grid th.ProseMirror-question-title-linked,
.ProseMirror custom-grid td.ProseMirror-question-title-linked {
  outline: none;
}

.ProseMirror custom-grid th.ProseMirror-question-title-linked::after,
.ProseMirror custom-grid td.ProseMirror-question-title-linked::after {
  position: absolute;
  content: "";
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  pointer-events: none;
  border: 2px dotted var(--question-title-linked-outline);
}
