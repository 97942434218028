.LinkDefinition {
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  width: 200px;
  font-size: 13px;
}

.LinkDefinition label,
.LinkDefinition input {
  width: 100%;
}

.LinkPropertiesPanel {
  width: 100%;
  font-size: 13px;
}

.LinkPropertiesPanel label,
.LinkPropertiesPanel input {
  width: 100%;
}
