.Editor {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  margin: 25px auto;
  width: 980px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.Editor .Content {
  flex: 1 1 auto;
  overflow: auto;
}

.sections {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  min-height: 0;
}

.left-side,
.right-side {
  display: flex;
  flex-direction: column;
}

.left-side {
  flex: 1 1 auto;
}

.right-side {
  flex: none;
  width: 400px;
  margin: 25px auto;
  background: #ffffff;
  border: 1px solid #e4e4e4;
}
