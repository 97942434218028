.ProseMirror constant-sum {
  display: block;
  justify-content: space-between;
  cursor: grab;
  padding: 4px;
}

.ProseMirror constant-sum-content {
  display: block;
  cursor: auto;
}

.ProseMirror constant-sum .constant-sum-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.ProseMirror constant-sum .constant-sum-container constant-sum-content {
  flex: 1 1 auto;
}

.ProseMirror constant-sum .constant-sum-container > * ~ * {
  margin-left: 8px;
}

.ProseMirror constant-sum .constant-sum-value-container {
  display: flex;
  align-items: baseline;
  flex: none;
}

.ProseMirror constant-sum .constant-sum-decorator:first-child {
  padding: 0;
  padding-right: 8px;
}

.ProseMirror constant-sum .constant-sum-decorator:last-child {
  padding: 0;
  padding-left: 8px;
}

.ProseMirror constant-sum .sum-value {
  font-weight: 900;
}

.constant-sum-result-cell {
  outline: 2px solid var(--document-element-outline);
  z-index: 2;
}

.ProseMirror constant-sum > .validation-messages:not(.hidden) {
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.ProseMirror constant-sum .validation-messages .required .icon {
  font-size: 10px;
  font-weight: 600;
  position: relative;
  right: -12px;
}
