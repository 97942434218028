/* Selection Overlay */
.ProseMirror-selection-overlay {
  --selection-overlay-text: #000000;

  position: absolute;
  z-index: 5;
  display: flex;
}

.ProseMirror-block-selector-dragging .ProseMirror-selection-overlay {
  pointer-events: none;
}

.ProseMirror[data-palette-type="light"] .ProseMirror-selection-overlay {
  --selection-overlay-text: #000000;
}

.ProseMirror[data-palette-type="dark"] .ProseMirror-selection-overlay {
  --selection-overlay-text: #ffffff;
}

.ProseMirror-selection-overlay .ProseMirror-selection-overlay-leading {
  flex: none;
  background-color: var(--selection-overlay-background);
  border-top: 1px solid var(--selection-overlay-border);
  border-bottom: 1px solid var(--selection-overlay-border);
  border-left: 1px solid var(--selection-overlay-border);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  font-weight: 700;
  color: var(--selection-overlay-text);
  line-height: 1.4;
}

.ProseMirror-selection-overlay .ProseMirror-selection-overlay-middle {
  flex: 1 1 auto;
  background-color: var(--selection-overlay-background);
  border: 1px solid var(--selection-overlay-border);
}

.ProseMirror-selection-overlay .ProseMirror-selection-overlay-trailing {
  flex: none;
  background-color: var(--selection-overlay-background);
  border-top: 1px solid var(--selection-overlay-border);
  border-bottom: 1px solid var(--selection-overlay-border);
  border-right: 1px solid var(--selection-overlay-border);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 1.4;
  color: var(--selection-overlay-border);
}

.ProseMirror-selection-overlay .ProseMirror-selection-overlay-trailing button {
  background-color: transparent;
  width: 100%;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.ProseMirror-selection-overlay:not(:hover)
  .ProseMirror-selection-overlay-trailing {
  visibility: hidden;
}

/* Target Overlay */
.ProseMirror-target-overlay {
  position: relative;
}

.ProseMirror-target-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  display: flex;
  border: 1px solid var(--target-overlay-border);
}

.ProseMirror-target-overlay .ProseMirror-target-overlay {
  z-index: 3;
}

/* Input Selector */
.ProseMirror-input-selector {
  --target-overlay-border: #ed9df3;
  --selection-overlay-border: #d209e1;
  --selection-overlay-background: rgba(210, 9, 225, 0.1);
}

/* Block Selector */
.ProseMirror-block-selector {
  --target-overlay-border: #a5eab3;
  --selection-overlay-border: #1ecb40;
  --selection-overlay-background: rgba(30, 203, 64, 0.1);
}

/* Fork Selector */
.ProseMirror-fork-selector {
  --target-overlay-border: #a5eab3;
  --selection-overlay-border: #1ecb40;
  --selection-overlay-background: rgba(30, 203, 64, 0.1);

  --custom-area-toolbar-border: var(--selection-overlay-border);
  --custom-area-navigation-disabled: #cccccc;
  --custom-area-active: var(--selection-overlay-border);
}

/* Inline Selector */
.ProseMirror-inline-selector {
  --target-overlay-border: #a5eab3;
  --selection-overlay-border: #1ecb40;
  --selection-overlay-background: rgba(30, 203, 64, 0.3);
  --inline-selector-background-color: #c7efc9;
}

.ProseMirror[data-palette-type="light"] .ProseMirror-inline-selector {
  --inline-selector-background-color: #c7efc9;
}

.ProseMirror[data-palette-type="dark"] .ProseMirror-inline-selector {
  --inline-selector-background-color: #0f3d15;
}

.ProseMirror-inline-selector-label {
  position: absolute;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  color: var(--selection-overlay-text);
  background-color: var(--inline-selector-background-color);
  border-right: 1px solid var(--selection-overlay-border);
}

.ProseMirror-inline-selector-button {
  position: absolute;
  font-size: 16px;
  line-height: 1;
  color: var(--selection-overlay-border);
  background-color: var(--inline-selector-background-color);
  border-left: 1px solid var(--selection-overlay-border);
}

.ProseMirror-inline-selector-button button {
  background-color: transparent;
  width: 100%;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.ProseMirror-inline-selector-outline {
  background-color: var(--selection-overlay-background);
}
