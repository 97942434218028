.ProseMirror .validation-messages {
  --validation-messages-required-color-1: rgba(173, 0, 0, 1);
  --validation-messages-required-color-2: rgba(19, 160, 113, 1);
  --validation-messages-font-weight-light: 300;
}

.ProseMirror .validation-messages:not(.hidden) {
  display: inline-flex;
  align-items: center;
  padding: 4px 0 8px 0;
}

.ProseMirror .validation-messages .required {
  position: relative;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: 13px;
  color: var(--validation-messages-required-color-1);
}

.ProseMirror .validation-messages .required.done {
  color: var(--validation-messages-required-color-2);
}

.ProseMirror .validation-messages .required .icon {
  display: inline-block;
  font-size: 15px;
  width: 12px;
  text-align: center;
}

.ProseMirror .validation-messages .required.done .icon {
  font-size: 19.5px;
}

.ProseMirror .validation-messages .required .text {
  display: inline-block;
  font-size: 13px;
  font-weight: var(--validation-messages-font-weight-light);
}

.ProseMirror .validation-messages .required .icon ~ .text {
  margin-left: 6px;
}
