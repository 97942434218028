.ProseMirror bar-chart {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
  --bar-chart-vertical-images-height: 295px;
  --bar-chart-vertical-default-height: 235px;
  --bar-chart-img-available-area-background-color: #f0f3f5;
  --bar-chart-vertical-img-square-size: 48px;
  --min-image-size-horizontal: 100px;
  --x-axis-container-margin-top: -18px;

  --bar-chart-color-1: rgba(0, 110, 219, 1);

  --bar-chart-legends-border-color: var(--bar-chart-color-1);
}

.ProseMirror[data-palette-type="light"] bar-chart {
  --bar-chart-footer-color: var(--color-scheme-theme-1);
}

.ProseMirror[data-palette-type="dark"] bar-chart {
  --bar-chart-footer-color: var(--color-scheme-theme-2);
}

.ProseMirror bar-chart[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror bar-chart[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror bar-chart .bar-chart-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ProseMirror bar-chart .bar-chart-empty-state .bx-chart-bar {
  font-size: 25px;
  margin-right: 10px;
}

.ProseMirror bar-chart .bar-chart-container .hide {
  display: none !important;
}

.ProseMirror bar-chart .bar-chart-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: center;
}

.ProseMirror bar-chart .bar-chart-container .bar-chart-info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ProseMirror bar-chart .bar-chart-container .bar-chart-area-container {
  overflow: hidden;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical
  .bar-chart-info-container
  .bar-chart-area-container {
  display: flex;
  flex-direction: column;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical.hidden-labels
  .bar-chart-info-container
  .bar-chart-area-container {
  display: block;
  height: inherit;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical
  .bar-chart-canvas-container {
  transition: height 0.2s linear;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical.images
  .bar-chart-canvas-container {
  height: var(--canvas-container-height-vertical-mode);
  overflow: hidden;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical
  .bar-chart-info-container
  .bar-chart-area-container {
  order: 0;
  width: 100%;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical.images
  .bar-chart-info-container
  .bar-chart-area-container {
  height: var(--bar-chart-vertical-images-height);
}

.ProseMirror
  bar-chart[data-legend="left"]
  .bar-chart-container.vertical.images
  .bar-chart-info-container
  .bar-chart-legends,
.ProseMirror
  bar-chart[data-legend="right"]
  .bar-chart-container.vertical.images
  .bar-chart-info-container
  .bar-chart-legends {
  height: var(--bar-chart-vertical-images-height);
  max-height: var(--bar-chart-vertical-images-height);
  min-width: 110px;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical.no-images
  .bar-chart-info-container
  .bar-chart-area-container {
  height: var(--bar-chart-vertical-default-height);
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical.no-images
  .bar-chart-canvas-container {
  height: 90%;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical.hidden-labels
  .bar-chart-canvas-container {
  height: 100%;
}

.ProseMirror
  bar-chart
  .bar-chart-container.horizontal
  .bar-chart-info-container
  .bar-chart-area-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  order: 0;
  overflow-x: hidden;
  height: var(--horizontal-bar-chart-container-height);
}

.ProseMirror
  bar-chart
  .bar-chart-container.horizontal
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-canvas-container {
  width: 80%;
  min-width: 70px;
  order: 1;
  transition: width 0.2s linear;
}

.ProseMirror
  bar-chart
  .bar-chart-container.horizontal.hidden-labels
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-canvas-container {
  width: 100%;
}

.ProseMirror
  bar-chart
  .bar-chart-container
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container {
  padding-bottom: var(--yaxis-padding-bottom);
}

.ProseMirror
  bar-chart
  .bar-chart-container
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container,
.ProseMirror
  bar-chart
  .bar-chart-container.no-images
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20%;
  min-width: 110px;
  order: 0;
  opacity: 1;
  transition: min-width 0.2s linear, width 0.2s linear, opacity 0.2s linear;
}

.ProseMirror
  bar-chart
  .bar-chart-container.horizontal.hidden-labels
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container,
.ProseMirror
  bar-chart
  .bar-chart-container.horizontal.hidden-labels.no-images
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container {
  width: 0%;
  min-width: 0px;
  opacity: 0;
}

.ProseMirror
  bar-chart
  .bar-chart-container.no-images
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container {
  justify-content: space-around;
}

.ProseMirror
  bar-chart
  .bar-chart-container
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container
  .bar-chart-yaxis-tick-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: var(--yaxis-tick-container-height);
}

.ProseMirror
  bar-chart
  .bar-chart-container.images
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container
  .bar-chart-yaxis-tick-container
  .bar-chart-yaxis-tick-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--bar-chart-img-available-area-background-color);
  max-width: var(--min-image-size-horizontal);
  height: var(--min-image-size-horizontal);
  min-height: var(--min-image-size-horizontal);
}

.ProseMirror bar-chart .bar-chart-container .yaxis-label {
  margin-top: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  max-height: 20px;
  max-width: 174px;
}

.ProseMirror bar-chart .bar-chart-container.no-images .yaxis-label {
  margin-top: 0px;
}

.ProseMirror bar-chart .bar-chart-xaxis-container {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-around;
  padding-left: var(--xaxis-container-padding-left);
  height: calc(
    100% - var(--canvas-container-height-vertical-mode) -
      var(--x-axis-container-margin-top)
  );
  max-height: calc(
    100% - var(--canvas-container-height-vertical-mode) -
      var(--x-axis-container-margin-top)
  );
  margin-top: -18px;
  opacity: 1;
  transition: height 0.2s linear, opacity 0.2s linear;
}

.ProseMirror
  bar-chart
  .bar-chart-container.vertical.hidden-labels
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-xaxis-container {
  height: 0;
  opacity: 0;
}

.ProseMirror
  bar-chart
  .bar-chart-xaxis-container
  .bar-chart-xaxis-tick-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.ProseMirror
  bar-chart
  .bar-chart-xaxis-container
  .bar-chart-xaxis-tick-container
  .bar-chart-xaxis-tick-img-container
  img.img-fit,
.ProseMirror
  bar-chart
  .bar-chart-yaxis-container
  .bar-chart-yaxis-tick-container
  .bar-chart-yaxis-tick-img-container
  img.img-fit {
  object-fit: contain;
  object-position: left;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.ProseMirror
  bar-chart
  .bar-chart-xaxis-container
  .bar-chart-xaxis-tick-container
  .bar-chart-xaxis-tick-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bar-chart-img-available-area-background-color);
  width: var(--bar-chart-vertical-img-square-size);
  height: var(--bar-chart-vertical-img-square-size);
  min-height: var(--bar-chart-vertical-img-square-size);
}

.ProseMirror bar-chart .bar-chart-xaxis-container .xaxis-label {
  margin-top: 8px;
  text-align: center;
  width: 95%;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ProseMirror bar-chart .bar-chart-empty-state .bar-chart-empty-text,
.ProseMirror bar-chart .bar-chart-footer-text {
  font-size: 12px;
}

.ProseMirror bar-chart .bar-chart-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 9px;
  background-color: var(--bar-chart-footer-color);
  padding: 8px;
}

.ProseMirror bar-chart .bar-chart-mocked-label {
  display: none;
  position: absolute;
  right: 0px;
  bottom: -20px;
  font-size: 11px;
  z-index: 1;
  border-radius: 3px;
  background: rgba(0, 110, 219, 1);
  color: rgba(255, 255, 255, 1);
  padding: 1px 9px;
}

.ProseMirror bar-chart.ProseMirror-focusednode .bar-chart-mocked-label {
  display: unset;
}

.ProseMirror bar-chart .bar-chart-footer-text ~ .bar-chart-footer-text {
  margin-top: 4px;
}

.ProseMirror bar-chart[data-legend="none"] .bar-chart-legends {
  display: none;
}

.ProseMirror bar-chart .bar-chart-legends {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.ProseMirror
  bar-chart[data-legend="top"]
  .bar-chart-container
  .bar-chart-info-container,
.ProseMirror
  bar-chart[data-legend="bottom"]
  .bar-chart-container
  .bar-chart-info-container {
  display: flex;
  flex-direction: column;
}

.ProseMirror
  bar-chart[data-legend="right"]
  .bar-chart-container
  .bar-chart-info-container
  .bar-chart-area-container,
.ProseMirror
  bar-chart[data-legend="left"]
  .bar-chart-container
  .bar-chart-info-container
  .bar-chart-area-container {
  width: 80%;
}

.ProseMirror bar-chart[data-legend="top"] .bar-chart-legends {
  order: -1;
  overflow: hidden;
  border-bottom: 2px dotted var(--bar-chart-legends-border-color);
}

.ProseMirror bar-chart[data-legend="bottom"] .bar-chart-legends {
  order: 1;
  overflow: hidden;
  margin-top: 10px;
  border-top: 2px dotted var(--bar-chart-legends-border-color);
}

.ProseMirror bar-chart[data-legend="left"] .bar-chart-legends,
.ProseMirror bar-chart[data-legend="right"] .bar-chart-legends {
  flex-direction: column;
  align-items: unset;
  height: inherit;
  width: 20%;
  overflow-y: auto;
  min-width: 90px;
}

.ProseMirror
  bar-chart[data-legend="left"]
  .bar-chart-container.vertical.no-images
  .bar-chart-info-container
  .bar-chart-legends,
.ProseMirror
  bar-chart[data-legend="right"]
  .bar-chart-container.vertical.no-images
  .bar-chart-info-container
  .bar-chart-legends {
  height: var(--bar-chart-vertical-default-height);
}

.ProseMirror bar-chart[data-legend="left"] .bar-chart-legends {
  order: -1;
  border-right: 2px dotted var(--bar-chart-legends-border-color);
}
.ProseMirror bar-chart[data-legend="right"] .bar-chart-legends {
  order: 1;
  border-left: 2px dotted var(--bar-chart-legends-border-color);
}

.ProseMirror
  bar-chart[data-legend="right"]
  .bar-chart-legend
  ~ .bar-chart-legend,
.ProseMirror
  bar-chart[data-legend="left"]
  .bar-chart-legend
  ~ .bar-chart-legend {
  margin-left: unset;
  margin-top: auto;
}

.ProseMirror bar-chart .bar-chart-legend {
  display: flex;
  align-items: center;
}

.ProseMirror bar-chart .bar-chart-legend-tag {
  width: 12px;
  height: 12px;
  min-width: 12px;
}

.ProseMirror bar-chart .bar-chart-legend-text {
  font-size: 12px;
  margin-left: 5px;
}

.ProseMirror bar-chart:not(.ProseMirror-focusednode) .bar-chart-legends {
  border-color: transparent;
}

.ProseMirror
  custom-grid
  th
  bar-chart.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle,
.ProseMirror
  custom-grid
  td
  bar-chart.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle {
  display: none;
}

.ProseMirror
  custom-grid
  td
  bar-chart
  .bar-chart-container.horizontal
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-canvas-container {
  min-width: 0;
  transition: unset;
}

.ProseMirror
  bar-chart
  .bar-chart-container
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container
  .bar-chart-yaxis-tick-container {
  overflow: hidden;
}

.ProseMirror
  custom-grid
  td
  bar-chart
  .bar-chart-container
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container {
  min-width: 0 !important;
  min-width: 0;
  transition: unset;
}

.ProseMirror
  custom-grid
  td
  bar-chart
  .bar-chart-container
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-yaxis-container {
  transition: unset !important;
}

.ProseMirror custom-grid td bar-chart .bar-chart-container .yaxis-label {
  margin-top: 0;
  text-align: left;
  white-space: pre-wrap;
  text-overflow: unset;
}

.ProseMirror
  custom-grid
  td
  bar-chart
  .bar-chart-container.horizontal
  .bar-chart-info-container
  .bar-chart-area-container
  .bar-chart-canvas-container {
  min-width: unset;
}

.ProseMirror
  custom-grid
  td
  bar-chart.vertical.images
  .bar-chart-xaxis-container {
  align-items: center;
}

.ProseMirror
  custom-grid
  td
  bar-chart
  .bar-chart-container.vertical.images
  .bar-chart-xaxis-container {
  align-items: initial;
}

.ProseMirror
  custom-grid
  td
  bar-chart
  .bar-chart-xaxis-container
  .bar-chart-xaxis-tick-container {
  max-height: unset;
}

.ProseMirror custom-grid td bar-chart .bar-chart-xaxis-container .xaxis-label {
  white-space: pre-wrap;
  line-height: normal;
  line-break: anywhere;
}

.ProseMirror
  custom-grid
  td
  bar-chart
  .bar-chart-container.vertical.images
  .bar-chart-xaxis-container
  .xaxis-label {
  text-align: center;
  white-space: pre-wrap;
  max-width: 90%;
  text-overflow: unset;
  line-height: unset;
  line-break: unset;
}
