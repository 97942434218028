.PropertiesPanel-FormControl ~ .PropertiesPanel-FormControl {
  margin-top: 4px;
}

.InputChoice-response-id-table {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.InputChoice-response-id-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InputChoice-response-id-table-row ~ .InputChoice-response-id-table-row {
  margin-top: 4px;
}

.InputChoice-response-id-table-header {
  font-weight: bold;
}

.InputChoice-response-id-table-header,
.InputChoice-response-id-table-cell {
  flex: 1 1 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
