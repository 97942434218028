.PropertiesPanel-FormControl ~ .PropertiesPanel-FormControl {
  margin-top: 4px;
}

.InputScale-response-id-table {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.InputScale-response-id-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.InputScale-response-id-table-row ~ .InputScale-response-id-table-row {
  margin-top: 4px;
}

.InputScale-response-id-table-header {
  font-weight: bold;
}

.InputScale-response-id-table-header,
.InputScale-response-id-table-cell {
  flex: 1 1 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
