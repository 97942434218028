.InsertImage {
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  width: 200px;
  font-size: 13px;
}

.InsertImage input {
  width: 100%;
}

.ImagePropertiesPanel {
  width: 100%;
  font-size: 13px;
}

.ImagePropertiesPanel label,
.ImagePropertiesPanel input {
  width: 100%;
}

.InsertImage-section-picker {
  display: flex;
}

.InsertImage-section-picker button.active {
  color: blue;
}
