.ToolbarTooltip-container {
  display: inline-block;
  position: relative;
}

.ToolbarTooltip {
  position: absolute;
  background-color: black;
  padding: 8px;
  margin-top: 4px;
  z-index: 51;
}

.ToolbarTooltip-title {
  white-space: nowrap;
  color: white;
  font-size: small;
  font-weight: bold;
}

.ToolbarTooltip-subtitle {
  white-space: nowrap;
  color: white;
  font-size: x-small;
}

.ToolbarTooltip-title + .ToolbarTooltip-subtitle {
  margin-top: 4px;
}
