@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: relative;
}

.ProseMirror-gapcursor span {
  caret-color: transparent;
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
}

.ProseMirror-gapcursor span:after {
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
  border-left: 1px solid;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
}

.ProseMirror-gapcursor.left span::after {
  left: -3px;
}

.ProseMirror-gapcursor.right span::after {
  right: -3px;
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}
