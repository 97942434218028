.PageBreakPropertiesPanel {
  width: 100%;
  font-size: 13px;
}

.PageBreakPropertiesPanel label,
.PageBreakPropertiesPanel input,
.PageBreakPropertiesPanel select {
  width: 100%;
}
