.ProseMirror input-choice {
  --input-choice-color-1: rgba(204, 204, 204, 1);
  --input-choice-color-2: rgba(112, 112, 112, 1);
  --input-choice-color-3: rgba(85, 85, 85, 1);
  --input-choice-color-4: rgba(255, 255, 255, 1);
  --input-choice-color-5: rgba(227, 227, 227, 1);

  --input-choice-bullet-color: var(--input-choice-color-2);
  --input-choice-border-color: var(--input-choice-color-1);
  --input-choice-background-color: var(--input-choice-color-4);
  --input-choice-hover-color: var(--input-choice-color-5);
  --input-choice-color: var(--input-choice-color-3);

  --input-choice-button-background-color: var(--input-choice-color-1);
  --input-choice-button-border-color: var(--input-choice-color-3);
  --input-choice-button-color: var(--input-choice-color-2);

  --input-choice-rounded-style-border-radius: 4px;
  --input-choice-rounded-style-slider-border-radius: 10px;
}

/*Themes and skins*/
.ProseMirror[data-palette-type="light"] input-choice {
  --input-choice-background-color: var(--color-scheme-primary-1);
  --input-choice-border-color: var(--color-scheme-theme-3);
  --input-choice-color: var(--color-scheme-theme-5);
  --input-choice-hover-color: var(--color-scheme-theme-2);

  --input-choice-button-background-color: var(--color-scheme-theme-1);
  --input-choice-button-border-color: var(--color-scheme-theme-3);
  --input-choice-button-color: var(--color-scheme-theme-5);

  --input-choice-bullet-color: var(--color-scheme-theme-4);
}

.ProseMirror[data-palette-type="dark"] input-choice {
  --input-choice-background-color: var(--color-scheme-primary-1);
  --input-choice-border-color: var(--color-scheme-theme-3);
  --input-choice-color: var(--color-scheme-theme-1);
  --input-choice-hover-color: var(--color-scheme-theme-2);

  --input-choice-button-background-color: var(--color-scheme-theme-1);
  --input-choice-button-border-color: var(--color-scheme-theme-3);
  --input-choice-button-color: var(--color-scheme-theme-5);

  --input-choice-bullet-color: var(--color-scheme-theme-4);
}

.ProseMirror input-choice {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
  position: relative;
}

.ProseMirror input-choice[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror input-choice[data-alignment="left"] {
  margin-right: auto;
}

.ProseMirror input-choice[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror input-choice-value,
.ProseMirror input-choice-other-specify-value {
  color: var(--input-choice-color);
}

.ProseMirror input-choice .bullet::before {
  border-radius: var(--input-choice-rounded-style-border-radius);
}

.ProseMirror input-choice[data-control-type="single-vertical"],
.ProseMirror input-choice[data-control-type="multiple-vertical"],
.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-other-specify-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-other-specify-value {
  padding: 6px;
}

.ProseMirror input-choice[data-control-type="single-vertical"] .bullet,
.ProseMirror input-choice[data-control-type="single-horizontal"] .bullet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
}

.ProseMirror input-choice[data-control-type="multiple-vertical"] .bullet,
.ProseMirror input-choice[data-control-type="multiple-horizontal"] .bullet {
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
}

.ProseMirror input-choice[data-control-type="dropdown-list"] .bullet,
.ProseMirror input-choice[data-control-type="list-box"] .bullet {
  width: 20px;
  height: 20px;
}

.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  .bullet {
  border-width: 0;
  border-style: unset;
}

.ProseMirror input-choice .bullet {
  cursor: pointer;
  color: var(--input-choice-bullet-color);
  background-color: var(--input-choice-background-color);
  position: relative;
}

.ProseMirror input-choice input-choice-value .bullet::selection,
.ProseMirror input-choice input-choice-other-specify-value .bullet::selection {
  background-color: transparent !important;
}

.ProseMirror input-choice[data-control-type="single-vertical"] .content,
.ProseMirror input-choice[data-control-type="multiple-vertical"] .content,
.ProseMirror input-choice[data-control-type="single-horizontal"] .content,
.ProseMirror input-choice[data-control-type="multiple-horizontal"] .content {
  cursor: text;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-value
  .content,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-value
  .content,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-value
  .content {
  flex: 1 1 0;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-value
  .content {
  width: 100%;
}

.ProseMirror input-choice-other-specify-value .other-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-other-specify-value
  .other-container,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-other-specify-value
  .other-container {
  width: 100%;
}

.ProseMirror input-choice-other-specify-value .inputText {
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-choice-border-color);
  background-color: var(--input-choice-background-color);
  height: 24px;
  padding-left: 5px;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-value
  .content {
  padding-left: unset;
}

.ProseMirror input-choice-values {
  display: block;
  padding: 1px;
}

.ProseMirror input-choice[data-control-type="dropdown-list"] input-choice-value,
.ProseMirror input-choice[data-control-type="list-box"] input-choice-value,
.ProseMirror input-choice-other-specify-value {
  display: flex;
  align-items: baseline;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-value
  ~ input-choice-value,
.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-value
  ~ input-choice-other-specify-value,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-value
  ~ input-choice-value,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-value
  ~ input-choice-other-specify-value {
  margin-top: 9px;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="bottom"]
  input-choice-value
  .bullet
  ~ .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="bottom"]
  input-choice-value
  .bullet
  ~ .content,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="bottom"]
  input-choice-other-specify-value
  .bullet
  ~ .other-container,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="bottom"]
  input-choice-other-specify-value
  .bullet
  ~ .other-container {
  margin-top: 10px;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="top"]
  input-choice-value
  .bullet
  ~ .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="top"]
  input-choice-value
  .bullet
  ~ .content,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="top"]
  input-choice-other-specify-value
  .bullet
  ~ .other-container,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="top"]
  input-choice-other-specify-value
  .bullet
  ~ .other-container {
  margin-bottom: 10px;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"]:not([data-label-position="boxed"])
  input-choice-other-specify-value
  .bullet
  ~ .other-container,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-other-specify-value
  .bullet
  ~ .other-container,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value
  .bullet
  ~ .other-container,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value
  .bullet
  ~ .other-container,
.ProseMirror
  input-choice[data-control-type="single-vertical"]:not([data-label-position="boxed"])
  .bullet
  ~ .content,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  .bullet
  ~ .content,
.ProseMirror input-choice[data-control-type="dropdown-list"] .bullet ~ .content,
.ProseMirror input-choice[data-control-type="list-box"] .bullet ~ .content {
  margin-left: 5px;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-other-specify-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-other-specify-value
  .content,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value
  .content,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value
  .content {
  margin-right: 5px;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-value
  ~ input-choice-value,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-value
  ~ input-choice-other-specify-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-value
  ~ input-choice-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-value
  ~ input-choice-other-specify-value {
  margin-left: 3px;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-other-specify-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-other-specify-value
  .content {
  min-width: 75%;
}

.ProseMirror input-choice-other-specify-value .content {
  min-width: 2px;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value
  .inputText {
  flex: 1 1 0;
  min-width: 150px;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-value {
  display: flex;
  align-items: baseline;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-other-specify-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-other-specify-value {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0;
  border: 1px solid transparent;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-other-specify-value
  .other-container,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-other-specify-value
  .other-container {
  flex-direction: column;
}

.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value {
  flex-direction: row;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-values,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-values {
  display: flex;
  overflow: auto;
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-values,
.ProseMirror input-choice[data-control-type="list-box"] input-choice-values {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 182px;

  background-color: var(--input-choice-background-color);
  border: 1.5px solid var(--input-choice-border-color);
}

.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-values
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-values
  input-choice-other-specify-value {
  padding: 8px 12px;
  cursor: pointer;
}

.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-value:hover,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value:hover,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value:hover
  .bullet,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-value:hover
  .bullet {
  background-color: var(--input-choice-hover-color);
}

.ProseMirror input-choice-value p {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 1.8rem;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"].ProseMirror-focusednode
  input-choice-value:not(:first-child),
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"].ProseMirror-focusednode
  input-choice-value:not(:first-child),
.ProseMirror
  input-choice[data-control-type="single-horizontal"].ProseMirror-focusednode
  input-choice-other-specify-value:not(:first-child),
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"].ProseMirror-focusednode
  input-choice-other-specify-value:not(:first-child) {
  border-left-color: var(--input-choice-border-color);
}

.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-value:not([data-choice-default])
  .bullet,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value:not([data-choice-default])
  .bullet,
.ProseMirror
  input-choice[data-control-type="dropdown-list"][data-choice-selection-max="1"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="list-box"][data-choice-selection-max="1"]
  .bullet {
  display: none;
}

/*ORIENTATION STYLE*/
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="top"]
  input-choice-value
  .bullet,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="top"]
  input-choice-value
  .bullet {
  order: 1;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="top"]
  input-choice-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="top"]
  input-choice-value
  .content {
  margin-top: auto;
  order: 0;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="bottom"]
  input-choice-value
  .bullet,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="bottom"]
  input-choice-value
  .bullet {
  order: 0;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="bottom"]
  input-choice-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="bottom"]
  input-choice-value
  .content {
  order: 1;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-value
  .bullet,
.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value
  .bullet,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-value
  .bullet,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value
  .bullet {
  display: none;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="top"]
  input-choice-other-specify-value
  .other-container,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="top"]
  input-choice-other-specify-value
  .other-container {
  margin-top: auto;
  order: 0;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="top"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="top"]
  input-choice-other-specify-value
  .inputText {
  order: 1;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="top"]
  input-choice-other-specify-value
  .bullet,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="top"]
  input-choice-other-specify-value
  .bullet {
  order: 2;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="bottom"]
  input-choice-other-specify-value
  .bullet,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="bottom"]
  input-choice-other-specify-value
  .bullet {
  order: 0;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="bottom"]
  input-choice-other-specify-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="bottom"]
  input-choice-other-specify-value
  .content {
  order: 1;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="bottom"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="bottom"]
  input-choice-other-specify-value
  .inputText {
  order: 2;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value
  .content {
  order: 0;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value
  .inputText {
  order: 1;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value
  .bullet,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value
  .bullet {
  order: 0;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"],
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"] {
  padding: unset;
}

.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value {
  border: 1px solid var(--input-choice-border-color);
  background-color: var(--input-choice-background-color);
  padding: 8px 10px;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value {
  justify-content: center;
  background-color: var(--input-choice-background-color);
  border: 1px solid var(--input-choice-border-color);
  padding: 8px 5px;
}

.ProseMirror input-choice-other-specify-value .content {
  cursor: text;
}
/* Dropdown */
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-dropdown-toggle {
  display: flex;
  height: 32px;
  border: 1px solid var(--input-choice-border-color);
  background-color: var(--input-choice-background-color);
  color: var(--input-choice-color);
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-dropdown-toggle-text {
  padding: 5px 8px;
  flex: 1 1 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-dropdown-toggle-carret {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.ProseMirror input-choice[data-control-type="dropdown-list"] .input-container {
  position: relative;
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-values {
  color: var(--input-choice-color);
  background-color: var(--input-choice-background-color);
  border: 1px solid var(--input-choice-border-color);
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: absolute;
  z-index: 41;
  left: 0;
  right: 0;
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"][data-dropdown-position="top"]
  input-choice-values {
  bottom: 36px;
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"][data-dropdown-position="bottom"]
  input-choice-values {
  top: 36px;
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"]:not([data-dropdown-opened])
  input-choice-values {
  display: none;
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-values
  input-choice-value,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-values
  input-choice-other-specify-value {
  cursor: pointer;
  padding: 8px;
}

.ProseMirror input-choice[data-control-type="dropdown-list"] .content,
.ProseMirror input-choice[data-control-type="list-box"] .content {
  cursor: text;
}

.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value:hover,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-value:hover,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value:hover
  .bullet,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-value:hover
  .bullet {
  background-color: var(--input-choice-hover-color);
}

/*Theme and skin*/
.ProseMirror[data-input-style="rounded"] input-choice-value,
.ProseMirror[data-input-style="rounded"] input-choice-other-specify-value,
.ProseMirror[data-input-style="rounded"]
  input-choice-other-specify-value
  .inputText,
.ProseMirror[data-input-style="rounded"]
  input-choice[data-control-type="multiple-vertical"]
  .bullet,
.ProseMirror[data-input-style="rounded"]
  input-choice[data-control-type="multiple-horizontal"]
  .bullet {
  border-radius: var(--input-choice-rounded-style-border-radius);
}

.ProseMirror[data-input-style="rounded"]
  input-choice[data-control-type="dropdown-list"]
  input-choice-dropdown-toggle,
.ProseMirror[data-input-style="rounded"]
  input-choice[data-control-type="dropdown-list"]
  input-choice-values {
  border-radius: var(--input-choice-rounded-style-border-radius);
}

.ProseMirror[data-input-style="simple"]
  input-choice[data-control-type="dropdown-list"]
  input-choice-dropdown-toggle,
.ProseMirror[data-input-style="simple"]
  input-choice-other-specify-value
  .inputText {
  background: transparent;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

/*Alignment*/
.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-value:not([data-alignment])
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-value:not([data-alignment])
  .content,
.ProseMirror
  input-choice[data-control-type="dropdown"]
  input-choice-value:not([data-alignment])
  .content,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-value:not([data-alignment])
  .content {
  text-align: left;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-other-specify-value:not([data-alignment])
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-other-specify-value:not([data-alignment])
  .content {
  text-align: center;
}

.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-value:not([data-alignment])
  .content,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-value:not([data-alignment])
  .content {
  text-align: center;
}

.ProseMirror input-choice-value[data-alignment="left"] .content,
.ProseMirror input-choice-other-specify-value[data-alignment="left"] .content {
  text-align: left;
}

.ProseMirror input-choice-value[data-alignment="center"] .content,
.ProseMirror
  input-choice-other-specify-value[data-alignment="center"]
  .content {
  text-align: center;
}

.ProseMirror input-choice-value[data-alignment="right"] .content,
.ProseMirror input-choice-other-specify-value[data-alignment="right"] .content {
  text-align: right;
}

.ProseMirror input-choice-value[data-alignment="justify"] .content,
.ProseMirror
  input-choice-other-specify-value[data-alignment="justify"]
  .content {
  text-align: justify;
}

/*DEFAULT VALUE*/
.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-value[data-choice-default="true"]
  .bullet::before,
.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-value[data-choice-default="true"]
  .bullet::before,
.ProseMirror
  input-choice[data-control-type="single-vertical"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet::before,
.ProseMirror
  input-choice[data-control-type="single-horizontal"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet::before {
  left: 4px;
  top: 50%;
  position: absolute;
  z-index: 2;
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translateY(-50%);
}

.ProseMirror
  input-choice[data-control-type="multiple-vertical"]:not([data-label-position="boxed"])
  input-choice-value[data-choice-default="true"]
  .bullet::before,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]:not([data-label-position="boxed"])
  input-choice-value[data-choice-default="true"]
  .bullet::before,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]:not([data-label-position="boxed"])
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet::before,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]:not([data-label-position="boxed"])
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet::before {
  display: block;
  position: absolute;
  content: "";
  left: -1px;
  top: 50%;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  transform: translateY(-50%);
}

.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-value[data-choice-default="true"]
  .bullet::after,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet::after,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet::after,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"]
  input-choice-value[data-choice-default="true"]
  .bullet::after,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-value[data-choice-default="true"]
  .bullet::after,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-value[data-choice-default="true"]
  .bullet::after,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet::after,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet::after {
  position: absolute;
  top: 50%;
  left: 4px;
  font-family: blueX;
  font-size: 1.5rem;
  content: "";
  transform: translateY(-50%);
}

.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-value[data-choice-default="true"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-value[data-choice-default="true"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value[data-choice-default="true"]
  .bullet {
  background-color: var(--input-choice-button-color);
  color: var(--input-choice-button-background-color);
}

.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"]
  .inputText,
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"]
  .inputText,
.ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"]
  .inputText,
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"]
  .inputText,
.ProseMirror
  input-choice[data-control-type="dropdown-list"]
  input-choice-other-specify-value[data-choice-default="true"]
  .inputText,
.ProseMirror
  input-choice[data-control-type="list-box"]
  input-choice-other-specify-value[data-choice-default="true"]
  .inputText {
  background-color: var(--input-choice-button-color);
  border-color: var(--input-choice-button-background-color);
}

.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"]
  .ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="single-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-vertical"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"]
  .ProseMirror
  input-choice[data-control-type="single-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"],
.ProseMirror
  input-choice[data-control-type="multiple-horizontal"][data-label-position="boxed"]
  input-choice-other-specify-value[data-choice-default="true"] {
  border-color: var(--input-choice-button-color);
}

.ProseMirror input-choice-value .bullet::before,
.ProseMirror input-choice-other-specify-value .bullet::before {
  background-color: var(--input-choice-bullet-color);
}

.ProseMirror input-choice-value .bullet::after,
.ProseMirror input-choice-other-specify-value .bullet::after {
  color: var(--input-choice-button-background-color);
}

.ProseMirror
  custom-grid
  th
  input-choice.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle,
.ProseMirror
  custom-grid
  td
  input-choice.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle {
  display: none;
}

.ProseMirror[data-selector="block"]
  input-choice[data-control-type="list-box"]
  input-choice-values,
.ProseMirror[data-selector="inline"]
  input-choice[data-control-type="list-box"]
  input-choice-values {
  max-height: none !important;
}

.ProseMirror[data-selector="block"]
  input-choice[data-control-type="dropdown-list"]
  input-choice-values,
.ProseMirror[data-selector="inline"]
  input-choice[data-control-type="dropdown-list"]
  input-choice-values {
  display: flex !important;
  max-height: none !important;
  position: initial !important;
  margin-top: 5px !important;
}

.ProseMirror custom-grid td input-choice {
  width: 100% !important;
}
