.QuestionTitlePropertiesPanel {
  width: 100%;
  font-size: 13px;
}

.QuestionTitlePropertiesPanel label,
.QuestionTitlePropertiesPanel input,
.QuestionTitlePropertiesPanel textarea {
  width: 100%;
}

.QuestionTitlePropertiesPanel-question {
  display: flex;
}

.QuestionTitlePropertiesPanel-question *:first-child {
  flex: 1;
}

.QuestionTitlePropertiesPanel-question *:not(:first-child) {
  flex: none;
}
