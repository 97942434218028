.ProseMirror input-datetime {
  --input-datetime-color-1: rgba(240, 240, 240, 1);
  --input-datetime-color-2: rgba(85, 85, 85, 1);
  --input-datetime-color-3: rgba(204, 204, 204, 1);
  --input-datetime-color-4: rgba(255, 255, 255, 1);

  --input-datetime-rounded-style-border-radius: 4px;

  --input-datetime-container-background-color: var(--input-datetime-color-4);
  --input-datetime-container-border-color: var(--input-datetime-color-3);
  --input-datetime-text-color: var(--input-datetime-color-2);
  --input-datetime-watermark-color: var(--input-datetime-color-2);
  --input-datetime-default-value-color: var(--input-datetime-color-2);
  --input-datetime-button-background-color: var(--input-datetime-color-1);
  --input-datetime-button-border-color: var(--input-datetime-color-3);
  --input-datetime-button-color: var(--input-datetime-color-2);
}

.ProseMirror[data-palette-type="light"] input-datetime {
  --input-datetime-container-background-color: var(--color-scheme-primary-1);
  --input-datetime-container-border-color: var(--color-scheme-theme-3);
  --input-datetime-text-color: var(--input-datetime-color-2);
  --input-datetime-watermark-color: var(--color-scheme-theme-5);
  --input-datetime-default-value-color: var(--color-scheme-theme-5);
  --input-datetime-button-background-color: var(--color-scheme-theme-1);
  --input-datetime-button-border-color: var(--color-scheme-theme-3);
  --input-datetime-button-color: var(--color-scheme-theme-5);
}

.ProseMirror[data-palette-type="dark"] input-datetime {
  --input-datetime-container-background-color: var(--color-scheme-primary-1);
  --input-datetime-container-border-color: var(--color-scheme-theme-3);
  --input-datetime-text-color: var(--input-datetime-color-2);
  --input-datetime-watermark-color: var(--color-scheme-theme-1);
  --input-datetime-default-value-color: var(--color-scheme-theme-1);
  --input-datetime-button-background-color: var(--color-scheme-theme-5);
  --input-datetime-button-border-color: var(--color-scheme-theme-3);
  --input-datetime-button-color: var(--color-scheme-theme-1);
}

.ProseMirror input-datetime {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
}

.ProseMirror input-datetime[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror input-datetime[data-alignment="left"] {
  margin-right: auto;
}

.ProseMirror input-datetime[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror input-datetime .input-container {
  background: var(--input-datetime-container-background-color);
  border: 1px solid var(--input-datetime-container-border-color);
  display: flex;
  align-items: center;
  height: 34px;
}

.ProseMirror input-datetime .date-time-text {
  flex: 1 1 auto;
  color: var(--input-datetime-text-color);
  font-size: 15px;
  height: 100%;
  line-height: 1.65;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ProseMirror input-datetime .date-time-text.watermark {
  color: var(--input-datetime-watermark-color);
}

.ProseMirror input-datetime .date-time-text.default-value {
  color: var(--input-datetime-default-value-color);
}

.ProseMirror input-datetime .date-time-button {
  color: var(--input-datetime-button-color);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--input-datetime-button-background-color);
  border-left: 1px solid var(--input-datetime-button-border-color);
  font-size: 1.25rem;
  height: 100%;
  width: 32px;
}

.ProseMirror input-datetime .date-time-button.date span.bx-time {
  display: none;
}

.ProseMirror input-datetime .date-time-button.time span.bx-date {
  display: none;
}

.ProseMirror[data-input-style="rounded"] input-datetime .input-container {
  border-radius: var(--input-datetime-rounded-style-border-radius);
}

.ProseMirror[data-input-style="rounded"] input-datetime .date-time-button {
  border-top-right-radius: var(--input-datetime-rounded-style-border-radius);
  border-bottom-right-radius: var(--input-datetime-rounded-style-border-radius);
}

.ProseMirror[data-input-style="simple"] input-datetime .input-container,
.ProseMirror[data-input-style="simple"] input-datetime .date-time-button {
  background: transparent;
}

.ProseMirror[data-input-style="simple"] input-datetime .input-container {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.ProseMirror[data-input-style="simple"] input-datetime .date-time-button {
  border-left-color: transparent;
}

.ProseMirror
  custom-grid
  th
  input-datetime.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle,
.ProseMirror
  custom-grid
  td
  input-datetime.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle {
  display: none;
}

.ProseMirror custom-grid td input-datetime {
  width: 100% !important;
}
