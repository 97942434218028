.BarChartPropertiesPanel {
  width: 100%;
  font-size: 13px;
}

.BarChartPropertiesPanel .BarChart-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.BarChartPropertiesPanel .BarChart-sublabel {
  margin-top: 15px;
  margin-bottom: 5px;
}

.BarChartPropertiesPanel hr {
  margin-top: 10px;
}

.BarChartPropertiesPanel .BarChart-mock-text {
  margin-top: 10px;
}

.BarChartPropertiesPanel-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.BarChartPropertiesPanel-table-row ~ .BarChartPropertiesPanel-table-row {
  margin-top: 4px;
}

.BarChartPropertiesPanel-table-pin {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}

.BarChartPropertiesPanel-table-cell {
  flex: 1 1 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.BarChartPropertiesPanel-table-pin ~ .BarChartPropertiesPanel-table-cell {
  margin-left: 10px;
}

.BarChartPropertiesPanel-table-row.Align-end
  .PropertiesPanel-FormControl.BarChartPropertiesPanel-table-cell {
  align-items: flex-end;
}

.BarChartPropertiesPanel-table-row.Align-end input {
  width: calc(100% - 12px);
}

.BarChartPropertiesPanel-table-row.Align-end i.bx.bx-thumbnail-icon {
  font-size: 18px;
}

.BarChartPropertiesPanel-input-setting-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 7px;
}

.BarChart-segment-mode-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.BarChart-segment-mode-selector {
  display: inherit;
}

.BarChart-segment-mode-button {
  cursor: pointer;
  display: inherit;
  align-items: center;
  padding: 0;
  background: transparent;
  height: auto;
  border: none;
  color: #c7c7cc;
}

.BarChart-segment-mode-button:hover {
  color: #0b37aa;
}

.BarChart-segment-mode-button:active {
  background: #e7e8eb;
}

.BarChart-segment-mode-button.active {
  color: #006edb;
}

.BarChart-segment-mode-button .bx {
  padding-right: 6px;
}

.BarChart-segment-mode-button .bx-settings-menu {
  font-size: 22px;
}

.BarChart-segment-mode-button .bx-webhooks {
  font-size: 15px;
}

.BarChart-segment-mode-button.align-right {
  margin-left: auto;
}

.BarChart-segment-mode-button ~ .BarChart-segment-mode-button {
  margin-left: 12px;
}

.BarChartPropertiesPanel-field-container {
  margin-top: 5px;
  flex: 1 1 0;
  margin-right: 5px;
}

.BarChartPropertiesPanel-segment-container {
  display: flex;
  flex: 1 1 0;
}

.BarChartPropertiesPanel-range-container {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

.BarChartPropertiesPanel-range-text {
  margin-left: 15px;
  margin-right: 15px;
}
