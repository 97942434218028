.ProseMirror custom-area {
  --custom-area-toolbar-color: #333333;
  --custom-area-toolbar-background: #fafafd;
  --custom-area-toolbar-border: #c7c7cc;
  --custom-area-border-light: rgba(199, 199, 204, 0.5);
  --custom-area-active: #006edb;
  --custom-area-navigation-disabled: #cccccc;
  display: block;
  position: relative;
  border: 1px solid var(--custom-area-border-light);
}

.ProseMirror custom-area-before {
  display: block;
  position: absolute;
  height: 100%;
  left: calc(-1 * var(--document-padding-left));
  width: 3px;
  background: var(--custom-area-active);
  border-bottom: 3px solid var(--custom-area-active);
  border-top: 3px solid var(--custom-area-active);
}

.ProseMirror custom-area custom-area-toolbar {
  opacity: 0.5;
  cursor: grab;
}

.ProseMirror custom-area[focusedNode] {
  border-color: var(--custom-area-toolbar-border);
}

.ProseMirror custom-area[focusedNode] custom-area-toolbar {
  opacity: 1;
}

.ProseMirror custom-area.ProseMirror-focusednode custom-area-toolbar {
  opacity: 1;
}

.ProseMirror content-variant {
  display: none;
  padding: 3px;
  cursor: auto;
}

.ProseMirror
  custom-area.ProseMirror-focusednode.ProseMirror-outline-hide
  content-variant[data-active] {
  outline: 2px solid var(--custom-area-active);
}

.ProseMirror content-variant[data-active] {
  display: block;
}

.ProseMirror custom-area-toolbar {
  display: flex;
  justify-content: space-between;
  color: var(--custom-area-toolbar-color);
  background: var(--custom-area-toolbar-background);
  border-bottom: 1px solid var(--custom-area-toolbar-border);
  padding: 16px 10px;
  height: 50px;
  font-size: 13px;
}

.ProseMirror custom-area-toolbar *::selection {
  background-color: transparent !important;
}

.ProseMirror custom-area-pagination {
  display: flex;
  align-items: flex-end;
}

.ProseMirror custom-area-pagination .bx-chevron {
  cursor: pointer;
  font-size: 16px;
}

.ProseMirror custom-area-pagination .bx-chevron[disabled] {
  cursor: default;
  color: var(--custom-area-navigation-disabled);
}

.ProseMirror custom-area-pagination .bx-chevron:hover {
  color: var(--custom-area-active);
}
.ProseMirror custom-area-pagination .bx-chevron[disabled]:hover {
  color: var(--custom-area-navigation-disabled);
}

.ProseMirror custom-area-arrow-previous {
  transform: rotate(90deg);
}

.ProseMirror custom-area-pagination-button {
  cursor: pointer;
}

.ProseMirror custom-area-pagination-button,
.ProseMirror custom-area-pagination-separator {
  font-weight: 700;
}

.ProseMirror custom-area-pagination-button.active,
.ProseMirror custom-area-pagination-button:hover {
  color: var(--custom-area-active);
}

.ProseMirror custom-area-arrow-previous ~ custom-area-pagination-button {
  margin-left: 33px;
}

.ProseMirror custom-area-pagination-button ~ custom-area-arrow-next {
  margin-left: 33px;
}

.ProseMirror custom-area-pagination-button ~ custom-area-pagination-button,
.ProseMirror custom-area-pagination-button ~ custom-area-pagination-separator {
  margin-left: 22px;
}

.ProseMirror custom-area-arrow-next {
  transform: rotate(-90deg);
}
