.ProseMirror page-break {
  --page-break-color-1: rgba(240, 240, 240, 1);
  --page-break-color-2: rgba(85, 85, 85, 1);
  --page-break-color-3: rgba(204, 204, 204, 1);
  --page-break-color-4: #041723;
  --page-break-color-5: #ffffff;
  --page-break-color-6: rgba(112, 112, 112, 1);

  --page-break-rounded-style-border-radius: 23px;
  --page-break-simple-style-border-radius: 4px;

  --page-break-border-color: var(--page-break-color-1);
  --page-break-button-color: var(--page-break-color-2);
  --page-break-button-background-color: var(--page-break-color-1);
  --page-break-button-border-color: var(--page-break-color-2);
  --page-break-button-disabled-color: var(--page-break-color-6);
  --page-break-button-disabled-background-color: var(--page-break-color-1);
  --page-break-button-disabled-border-color: var(--page-break-color-3);
  --page-break-page-indicator-color: var(--page-break-color-2);
  --page-break-page-indicator-border-color: var(--page-break-color-2);
  --page-break-enable-next-background-color: var(--page-break-color-1);
  --page-break-enable-next-icon-color: var(--page-break-color-2);
  --page-break-enable-next-tooltip-color: var(--page-break-color-5);
  --page-break-enable-next-tooltip-background-color: var(--page-break-color-4);
}

.ProseMirror[data-palette-type="light"] page-break {
  --page-break-border-color: var(--color-scheme-theme-5);
  --page-break-button-color: var(--color-scheme-theme-5);
  --page-break-button-background-color: var(--color-scheme-theme-1);
  --page-break-button-border-color: var(--color-scheme-theme-5);
  --page-break-button-disabled-color: var(--color-scheme-theme-4);
  --page-break-button-disabled-background-color: var(--color-scheme-theme-1);
  --page-break-button-disabled-border-color: var(--color-scheme-theme-3);
  --page-break-page-indicator-color: var(--color-scheme-theme-5);
  --page-break-page-indicator-border-color: var(--color-scheme-theme-5);
  --page-break-enable-next-background-color: var(--color-scheme-theme-1);
  --page-break-enable-next-icon-color: var(--color-scheme-theme-5);
  --page-break-enable-next-tooltip-color: var(--page-break-color-5);
  --page-break-enable-next-tooltip-background-color: var(--page-break-color-4);
}

.ProseMirror[data-palette-type="dark"] page-break {
  --page-break-border-color: var(--color-scheme-theme-1);
  --page-break-button-color: var(--color-scheme-theme-1);
  --page-break-button-background-color: var(--color-scheme-theme-5);
  --page-break-button-border-color: var(--color-scheme-theme-5);
  --page-break-button-disabled-color: var(--color-scheme-theme-3);
  --page-break-button-disabled-background-color: var(--color-scheme-theme-2);
  --page-break-button-disabled-border-color: var(--color-scheme-theme-3);
  --page-break-page-indicator-color: var(--color-scheme-theme-1);
  --page-break-page-indicator-border-color: var(--color-scheme-theme-1);
  --page-break-enable-next-background-color: var(--color-scheme-theme-5);
  --page-break-enable-next-icon-color: var(--color-scheme-theme-1);
  --page-break-enable-next-tooltip-color: var(--page-break-color-4);
  --page-break-enable-next-tooltip-background-color: var(--page-break-color-5);
}

.ProseMirror page-break {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--page-break-border-color);
  cursor: grab;
}

.ProseMirror page-break-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 15px 5px;
  align-items: center;
}

.ProseMirror page-break[data-alignment="left"] page-break-buttons {
  justify-content: flex-start;
}

.ProseMirror page-break[data-alignment="center"] page-break-buttons {
  justify-content: center;
}

.ProseMirror page-break[data-alignment="right"] page-break-buttons {
  justify-content: flex-end;
}

.ProseMirror page-break[data-alignment="justify"] page-break-buttons {
  justify-content: flex-end;
}

.ProseMirror page-break previous-page-break-button,
.ProseMirror page-break next-page-break-button,
.ProseMirror page-break submit-page-break-button {
  display: inline-block;
  height: fit-content;
  text-align: center;
  padding: 13px 20px;
  min-width: 43px; /*20px paddingLeft + 20px paddingRight + 1px borderLeft + 1px borderRight + 1px gap for cursor */
  margin: 5px;
  cursor: auto;
  border-width: 1px;
  border-style: solid;
  border-color: var(--page-break-button-border-color);
  color: var(--page-break-button-color);
  background-color: var(--page-break-button-background-color);
}

.ProseMirror page-break.only previous-page-break-button,
.ProseMirror page-break.only next-page-break-button {
  display: none;
}

.ProseMirror page-break.first previous-page-break-button,
.ProseMirror page-break.first submit-page-break-button {
  display: none;
}

.ProseMirror page-break.last next-page-break-button {
  display: none;
}

.ProseMirror
  page-break:not(.only):not(.first):not(.last)
  submit-page-break-button {
  display: none;
}

.ProseMirror page-break page-break-properties {
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
}

.ProseMirror page-break page-break-page-indicator {
  flex: auto;
  display: block;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  color: var(--page-break-page-indicator-color);
}

.ProseMirror page-break page-break-page-indicator::selection {
  background-color: transparent !important;
}

.ProseMirror page-break page-break-page-indicator::before,
.ProseMirror page-break page-break-page-indicator::after {
  content: "";
  display: inline-block;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--page-break-page-indicator-border-color);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--page-break-page-indicator-border-color);
  height: 4px;
  width: 50%;
  position: relative;
  vertical-align: middle;
}

.ProseMirror page-break page-break-page-indicator::before {
  margin-left: -50%;
  left: -8px;
}

.ProseMirror page-break page-break-page-indicator::after {
  margin-right: -50%;
  right: -8px;
}

.ProseMirror page-break page-break-enable-next {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--page-break-enable-next-background-color);
}

.ProseMirror
  page-break
  page-break-enable-next:not(:hover)
  page-break-enable-next-tooltip {
  display: none;
}

.ProseMirror page-break page-break-enable-next-icon {
  font-size: 15px;
  color: var(--page-break-enable-next-icon-color);
}

.ProseMirror page-break page-break-enable-next-icon.bx-download-arrow {
  transform: rotate(-90deg);
}

.ProseMirror page-break page-break-enable-next-icon.bx-input-number {
  font-size: 12px;
}

.ProseMirror page-break page-break-enable-next-tooltip {
  position: absolute;
  top: 27px;
  right: 0;
  white-space: nowrap;
  font-size: 10px;
  line-height: 13px;
  padding: 2px 4px;
  color: var(--page-break-enable-next-tooltip-color);
  background-color: var(--page-break-enable-next-tooltip-background-color);
}

.ProseMirror page-break page-break-enable-next-tooltip::after {
  content: "";
  position: absolute;
  top: -10px;
  right: 5px;
  border-width: 5px;
  border-style: solid;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--page-break-enable-next-tooltip-background-color);
}

.ProseMirror
  page-break[data-enable-previous="hidden"]
  previous-page-break-button {
  display: none;
}

.ProseMirror
  page-break[data-enable-previous="disabled"]
  previous-page-break-button {
  color: var(--page-break-button-disabled-color);
  background-color: var(--page-break-button-disabled-background-color);
  border-color: var(--page-break-button-disabled-border-color);
}

.ProseMirror[data-input-style="rounded"] page-break previous-page-break-button,
.ProseMirror[data-input-style="rounded"] page-break next-page-break-button,
.ProseMirror[data-input-style="rounded"] page-break submit-page-break-button {
  border-radius: var(--page-break-rounded-style-border-radius);
}

.ProseMirror[data-input-style="simple"] page-break previous-page-break-button,
.ProseMirror[data-input-style="simple"] page-break next-page-break-button,
.ProseMirror[data-input-style="simple"] page-break submit-page-break-button {
  border-radius: var(--page-break-simple-style-border-radius);
}
