.InputRankPropertiesPanel {
  width: 100%;
  font-size: 13px;
}
.InputRankPropertiesPanel label,
.InputRankPropertiesPanel input,
.InputRankPropertiesPanel select {
  width: 100%;
}
.InputRankPropertiesPanel input[type="checkbox"] {
  width: auto;
}

.InputRankPropertiesPanel .InputRank-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.InputRankPropertiesPanel .InputRank-label:not(:first-child) {
  margin-top: 10px;
}

.InputRankPropertiesPanel hr {
  margin-top: 10px;
}
.InputRankPropertiesPanel {
  width: 100%;
  font-size: 13px;
}
.InputRankPropertiesPanel label,
.InputRankPropertiesPanel input,
.InputRankPropertiesPanel select {
  width: 100%;
}
.InputRankPropertiesPanel input[type="checkbox"] {
  width: auto;
}

.InputRankPropertiesPanel .InputRank-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.InputRankPropertiesPanel .InputRank-label:not(:first-child) {
  margin-top: 10px;
}

.InputRankPropertiesPanel hr {
  margin-top: 10px;
}
