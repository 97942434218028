.ProseMirror input-rank {
  --input-rank-color-1: rgba(255, 255, 255, 1);
  --input-rank-color-2: rgba(85, 85, 85, 1);
  --input-rank-color-3: rgba(199, 199, 204, 1);
  --input-rank-color-4: rgba(250, 250, 250, 1);
  --input-rank-color-5: rgba(195, 204, 213, 1);
  --input-rank-color-6: rgba(238, 238, 241, 1);
  --input-rank-color-7: rgba(92, 102, 112, 1);
  --input-rank-color-8: rgba(11, 55, 170, 1);
  --input-rank-color-9: rgba(242, 248, 255, 1);

  --input-rank-container-background-color: var(--input-rank-color-4);
  --input-rank-container-border-color: var(--input-rank-color-3);
  --input-rank-text-color: var(--input-rank-color-2);
  --input-rank-container-color: var(--input-rank-color-2);
  --input-rank-default-value-color: var(--input-rank-color-2);
  --input-rank-watermark-color: var(--input-rank-color-2);
  --input-rank-button-background: var(--input-rank-color-2);
  --input-rank-button-color: var(--input-rank-color-1);

  --input-rank-header-background: var(--input-rank-color-4);

  --input-rank-dropdown-background: var(--input-rank-color-1);
  --input-rank-dropdown-bullet-border: var(--input-rank-color-5);
  --input-rank-dropdown-option-hover: var(--input-rank-color-4);

  --input-rank-dropdown-border-color: var(--input-rank-color-6);
  --input-rank-dropdown-item-color: var(--input-rank-color-7);
  --input-rank-dropdown-item-background-hover: var(--input-rank-color-9);

  --input-rank-default-style-button-border-radius: 3px;
  --input-rank-rounded-style-button-border-radius: 50%;
}

.ProseMirror[data-palette-type="light"] input-rank {
  --input-rank-container-background-color: var(--color-scheme-theme-1);
  --input-rank-container-border-color: var(--color-scheme-theme-3);
  --input-rank-text-color: var(--color-scheme-theme-5);
  --input-rank-button-background: var(--color-scheme-theme-5);
  --input-rank-button-color: var(--color-scheme-theme-1);
  --input-rank-dropdown-item-color: var(--color-scheme-theme-5);
  --input-rank-dropdown-option-hover: var(--color-scheme-theme-1);
  --input-rank-dropdown-item-background-hover: var(--color-scheme-theme-1);
  --input-rank-dropdown-background: var(--color-scheme-primary-1);
}

.ProseMirror[data-palette-type="dark"] input-rank {
  --input-rank-container-background-color: var(--color-scheme-primary-1);
  --input-rank-container-border-color: var(--color-scheme-theme-3);
  --input-rank-text-color: var(--color-scheme-theme-1);
  --input-rank-button-background: var(--color-scheme-theme-5);
  --input-rank-button-color: var(--color-scheme-theme-1);
  --input-rank-dropdown-item-color: var(--color-scheme-theme-1);
  --input-rank-dropdown-option-hover: var(--color-scheme-theme-2);
  --input-rank-dropdown-item-background-hover: var(--color-scheme-theme-2);
  --input-rank-dropdown-background: var(--color-scheme-primary-1);
}

.ProseMirror input-rank {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
}

.ProseMirror input-rank[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror input-rank[data-alignment="left"] {
  margin-right: auto;
}

.ProseMirror input-rank[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror input-rank[data-control-type="drag-and-drop"] .input-container {
  position: relative;
  padding-bottom: 10px;
}

.ProseMirror input-rank .rank-text {
  flex: 1 1 auto;
  color: var(--input-rank-text-color);
  font-size: 15px;
  height: 100%;
  line-height: 1.65;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**OPTIONS**/
.ProseMirror
  input-rank[data-control-type="drag-and-drop"]:not([data-dropdown-opened])
  input-rank-options {
  display: none;
}

/**DRAG AND DROP STYLE**/
.ProseMirror input-rank[data-control-type="drag-and-drop"] input-rank-options {
  background-color: var(--input-rank-dropdown-background);
  border: 1px solid var(--input-rank-container-border-color);
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  z-index: 200;
  transform: translate(0px, 5px);
}

.ProseMirror input-rank.options-hide input-rank-options {
  display: none;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify,
.ProseMirror input-rank[data-control-type="drag-and-drop"] input-rank-option {
  display: flex;
  align-items: baseline;
  padding: 8px 10px;
  cursor: pointer;
  color: var(--input-rank-text-color);
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify:hover,
.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify:hover
  .bullet,
.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option:hover,
.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option:hover
  .bullet {
  background: var(--input-rank-dropdown-option-hover);
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify
  .bullet,
.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option
  .bullet {
  height: 14px;
  width: 14px;
  transform: translateY(4px);
  background: var(--input-rank-dropdown-background);
  border: 1px solid var(--input-rank-text-color);
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option
  .content {
  flex: 1 1 0;
  cursor: text;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option
  .bullet
  ~ .content,
.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify
  .bullet
  ~ .other-container {
  margin-left: 6px;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify
  .content {
  cursor: text;
  min-width: 2px;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify
  .other-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify
  .inputText {
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-rank-container-border-color);
  background-color: var(--input-rank-dropdown-background);
  height: 24px;
  flex: 1 1 0;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-option-other-specify
  .content
  ~ .inputText {
  margin-left: 10px;
}
.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-dropdown-container {
  display: none;
}

/**DROPDOWN LIST**/
.ProseMirror input-rank[data-control-type="dropdown-list"] input-rank-options {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.ProseMirror input-rank[data-control-type="dropdown-list"] input-rank-option {
  display: table-row;
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-option
  .content {
  display: table-cell;
  padding: 10px 16px;
  width: 25%;
  border: 1px solid var(--input-rank-container-border-color);
  border-bottom-width: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-option
  input-rank-dropdown-container {
  display: table-cell;
  padding: 10px 16px;
  width: 25%;
  border: 1px solid var(--input-rank-container-border-color);
  border-bottom-width: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-option
  input-rank-dropdown-container:last-child {
  border-right-width: 0px;
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-dropdown-container
  input-rank-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 34px;
  border: 1px solid var(--input-rank-container-border-color);
  padding: 5px 10px;
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-dropdown-container
  .bx-caret {
  transform: rotate(180deg);
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-option
  .bullet {
  display: none;
}

/**SECTIONS**/
.ProseMirror input-rank-section .content {
  cursor: text;
}

/**DRAG AND DROP STYLE**/
.ProseMirror input-rank[data-control-type="drag-and-drop"] input-rank-sections {
  display: flex;
  flex-wrap: wrap;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"].ProseMirror-focusednode
  input-rank-section:nth-child(n + 9) {
  max-width: 23.8%;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]:not(.ProseMirror-focusednode)
  input-rank-section:nth-child(n + 5) {
  max-width: 23.8%;
}

.ProseMirror input-rank[data-control-type="drag-and-drop"]rank-sections {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 0;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-section
  .content {
  flex: 1 1 0;
  padding: 2px;
  margin-right: 10px;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank
  .input-rank-questions {
  position: absolute;
  z-index: 200;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-section
  .input-rank-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-left: auto;
  cursor: pointer;
}

.ProseMirror input-rank[data-control-type="drag-and-drop"] input-rank-section {
  background-color: var(--input-rank-container-background-color);
  border: 1px solid var(--input-rank-container-border-color);
  color: var(--input-rank-text-color);
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 10px;
  min-width: 23.8%;
  height: fit-content;
  flex: 1 1 23%;
  margin-right: 10px;
  margin-top: 10px;
}

/**DROPDOWN-LIST STYLE**/
.ProseMirror input-rank[data-control-type="dropdown-list"] input-rank-sections {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-sections
  input-rank-section-empty {
  display: table-cell;
  padding: 10px 16px;
  width: 25%;
  border: 1px solid var(--input-rank-container-border-color);
  border-bottom-width: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-sections
  input-rank-section {
  display: table-cell;
  padding: 10px 16px;
  width: 25%;
  border: 1px solid var(--input-rank-container-border-color);
  border-bottom-width: 0px;
  border-top-width: 0px;
  border-left-width: 0px;
}

.ProseMirror
  input-rank[data-control-type="dropdown-list"]
  input-rank-sections
  input-rank-section:last-child {
  border-right-width: 0px;
}

/**SECTION DROPDOWN**/
.ProseMirror .ProseMirror-rank-section-dropdown {
  --input-rank-section-dropdown-toggle-background: #006edb;
  --input-rank-section-dropdown-toggle-color: #ffffff;

  position: relative;
  display: inline-flex;
  flex-direction: column;
  right: 41px;
  top: 21px;
}

.ProseMirror .ProseMirror-rank-section-dropdown .ProseMirror-dropdown-toggle {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: var(--input-rank-section-dropdown-toggle-background);
}

.ProseMirror .ProseMirror-rank-section-dropdown .ProseMirror-dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 200;
  padding: 10px 0;
  background: #ffffff;
  border: 1px solid #eeeef1;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  font-size: 0.75rem;
  overflow: auto;
  width: auto;
}

.ProseMirror .ProseMirror-rank-section-dropdown .ProseMirror-dropdown-item {
  font-size: 0.75rem;
  color: #5c6670;
  padding: 7px 10px;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
}

.ProseMirror
  .ProseMirror-rank-section-dropdown
  .ProseMirror-dropdown-item:hover {
  color: #0b37aa;
  background: #f2f8ff;
}

.ProseMirror
  .ProseMirror-rank-section-dropdown
  .ProseMirror-dropdown-toggle
  .triangle {
  color: var(--input-rank-section-dropdown-toggle-color);
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.ProseMirror .ProseMirror-rank-section-dropdown .ProseMirror-dropdown-toggle {
  position: absolute;
}

.ProseMirror
  .ProseMirror-rank-section-dropdown[data-dropdown-position="top-right"]
  .ProseMirror-dropdown-content {
  bottom: 58px;
  right: -20px;
}

.ProseMirror
  .ProseMirror-rank-section-dropdown[data-dropdown-position="top-left"]
  .ProseMirror-dropdown-content {
  bottom: 58px;
  left: 0px;
}

.ProseMirror
  .ProseMirror-rank-section-dropdown[data-dropdown-position="bottom-right"]
  .ProseMirror-dropdown-content {
  top: 28px;
  right: -20px;
}

.ProseMirror
  .ProseMirror-rank-section-dropdown[data-dropdown-position="bottom-left"]
  .ProseMirror-dropdown-content {
  top: 28px;
  left: 0px;
}

.ProseMirror .InputRankSectionDropdown {
  display: inline-flex;
  flex-direction: column;
  width: 134px;
  max-height: 300px;
  border: 1px solid var(--input-rank-dropdown-border-color);
  background: var(--input-rank-dropdown-background);
  overflow: auto;
  padding: 10px 0px;
}

.ProseMirror .InputRankSectionDropdownOption {
  cursor: pointer;
  padding: 7px 10px;
  font-size: 12px;
  color: var(--input-rank-dropdown-item-color);
}

.ProseMirror .InputRankSectionDropdownOption:hover {
  background: var(--input-rank-dropdown-item-background-hover);
}

/**ALIGNMENT**/
.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-section:not([data-alignment])
  .content {
  text-align: left;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-section[data-alignment="center"]
  .content {
  text-align: center;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-section[data-alignment="right"]
  .content {
  text-align: right;
}

.ProseMirror
  input-rank[data-control-type="drag-and-drop"]
  input-rank-section[data-alignment="justify"]
  .content {
  text-align: justify;
}

.ProseMirror input-rank-option:not([data-alignment]) .content,
.ProseMirror input-rank-option-other-specify:not([data-alignment]) .content {
  text-align: left;
}

.ProseMirror input-rank-option[data-alignment="center"] .content,
.ProseMirror input-rank-option-other-specify[data-alignment="center"] .content {
  text-align: center;
}

.ProseMirror input-rank-option[data-alignment="right"] .content,
.ProseMirror input-rank-option-other-specify[data-alignment="right"] .content {
  text-align: right;
}

.ProseMirror input-rank-option[data-alignment="justify"] .content,
.ProseMirror
  input-rank-option-other-specify[data-alignment="justify"]
  .content {
  text-align: justify;
}

/*Theme and skin*/
.ProseMirror[data-input-style="default"] input-rank .input-rank-button,
.ProseMirror[data-input-style="rounded"] input-rank .input-rank-button {
  background-color: var(--input-rank-button-background);
  color: var(--input-rank-button-color);
}

.ProseMirror[data-input-style="simple"] input-rank .input-rank-button {
  border: 1px solid var(--input-rank-button-background);
  color: var(--input-rank-button-background);
  background-color: var(--input-rank-container-background-color);
}

.ProseMirror[data-input-style="simple"] input-rank .input-rank-button {
  border-radius: var(--input-rank-default-style-button-border-radius);
  background: transparent;
  border: transparent;
  color: var(--input-rank-text-color);
}

.ProseMirror[data-input-style="simple"] input-rank input-rank-section {
  background: transparent;
}

.ProseMirror[data-input-style="rounded"] input-rank .input-rank-button {
  border-radius: var(--input-rank-rounded-style-button-border-radius);
}

.ProseMirror
  custom-grid
  th
  input-rank.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle,
.ProseMirror
  custom-grid
  td
  input-rank.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle {
  display: none;
}

.ProseMirror.ProseMirror-disable-selection
  input-rank
  .ProseMirror-rank-section-dropdown {
  display: none;
}

.ProseMirror.ProseMirror-disable-selection input-rank input-rank-options {
  display: none;
}

.ProseMirror.ProseMirror-readonly
  input-rank.ProseMirror-focusednode
  .ProseMirror-rank-section-dropdown {
  display: none;
}
