@font-face {
  font-family: 'blueX';
  src:
    url('fonts/blueX.ttf?v5nfq') format('truetype'),
    url('fonts/blueX.woff?v5nfq') format('woff'),
    url('fonts/blueX.svg?v5nfq#blueX') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.bx {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'blueX' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bx.bx-submission:before {
  content: "\e95d";
}
.bx.bx-private-event:before {
  content: "\e95b";
}
.bx.bx-private-date:before {
  content: "\e95c";
}
.bx.bx-drag:before {
  content: "\e95a";
}
.bx.bx-ranking-drag:before {
  content: "\e955";
}
.bx.bx-ranking-grid:before {
  content: "\e956";
}
.bx.bx-bar-horizontal:before {
  content: "\e95e";
}
.bx.bx-bar-large:before {
  content: "\e95f";
}
.bx.bx-bar-medium:before {
  content: "\e960";
}
.bx.bx-bar-small:before {
  content: "\e961";
}
.bx.bx-bar-vertical:before {
  content: "\e962";
}
.bx.bx-chart-bar:before {
  content: "\e963";
}
.bx.bx-chart-crosstab:before {
  content: "\e964";
}
.bx.bx-chart-pie:before {
  content: "\e965";
}
.bx.bx-chart-table:before {
  content: "\e966";
}
.bx.bx-donut:before {
  content: "\e967";
}
.bx.bx-exclude:before {
  content: "\e968";
}
.bx.bx-pie-large:before {
  content: "\e969";
}
.bx.bx-pie-medium:before {
  content: "\e96a";
}
.bx.bx-pie-small:before {
  content: "\e96b";
}
.bx.bx-pie:before {
  content: "\e96c";
}
.bx.bx-switch:before {
  content: "\e96d";
}
.bx.bx-thumbnail-icon:before {
  content: "\e9a7";
}
.bx.bx-function:before {
  content: "\e957";
}
.bx.bx-horizontal-sum:before {
  content: "\e958";
}
.bx.bx-vertical-sum:before {
  content: "\e959";
}
.bx.bx-favorite-solid:before {
  content: "\e94e";
}
.bx.bx-font-bg:before {
  content: "\e94f";
}
.bx.bx-font-color:before {
  content: "\e950";
}
.bx.bx-language:before {
  content: "\e952";
}
.bx.bx-notification-center:before {
  content: "\e953";
}
.bx.bx-web:before {
  content: "\e954";
}
.bx.bx-import:before {
  content: "\e94d";
}
.bx.bx-questions:before {
  content: "\e94b";
}
.bx.bx-webhooks:before {
  content: "\e94c";
}
.bx.bx-refresh-circle:before {
  content: "\e94a";
}
.bx.bx-remove:before {
  content: "\e947";
}
.bx.bx-add:before {
  content: "\e948";
}
.bx.bx-contacts:before {
  content: "\e949";
}
.bx.bx-info:before {
  content: "\e946";
}
.bx.bx-tile:before {
  content: "\e942";
}
.bx.bx-grid:before {
  content: "\e943";
}
.bx.bx-resend:before {
  content: "\e944";
}
.bx.bx-horizontal-dot:before {
  content: "\e945";
}
.bx.bx-advanced-filter:before {
  content: "\e940";
}
.bx.bx-spinner-line:before {
  content: "\e941";
}
.bx.bx-camera:before {
  content: "\e93f";
}
.bx.bx-user-management:before {
  content: "\e93d";
}
.bx.bx-download:before {
  content: "\e93e";
}
.bx.bx-refresh:before {
  content: "\e93c";
}
.bx.bx-comment:before {
  content: "\e931";
}
.bx.bx-survey-management:before {
  content: "\e93a";
}
.bx.bx-versioning:before {
  content: "\e93b";
}
.bx.bx-myprofile:before {
  content: "\e937";
}
.bx.bx-helpcenter:before {
  content: "\e938";
}
.bx.bx-signout:before {
  content: "\e939";
}
.bx.bx-profile:before {
  content: "\e932";
}
.bx.bx-role:before {
  content: "\e933";
}
.bx.bx-deactivate:before {
  content: "\e934";
}
.bx.bx-view-task:before {
  content: "\e935";
}
.bx.bx-open-task:before {
  content: "\e936";
}
.bx.bx-caret:before {
  content: "\e930";
}
.bx.bx-folder-open:before {
  content: "\e90a";
}
.bx.bx-respondents:before {
  content: "\e900";
}
.bx.bx-vertical-dot:before {
  content: "\e901";
}
.bx.bx-action-close:before {
  content: "\e902";
}
.bx.bx-checkmark:before {
  content: "\e903";
}
.bx.bx-chevron-right-thin:before {
  content: "\e904";
}
.bx.bx-circle-plus:before {
  content: "\e905";
}
.bx.bx-close-circle:before {
  content: "\e906";
}
.bx.bx-close-properties:before {
  content: "\e907";
}
.bx.bx-colors:before {
  content: "\e908";
}
.bx.bx-favorite:before {
  content: "\e909";
}
.bx.bx-folder-close:before {
  content: "\e90b";
}
.bx.bx-label-bottom:before {
  content: "\e90c";
}
.bx.bx-label-left:before {
  content: "\e90d";
}
.bx.bx-label-boxed:before {
  content: "\e90e";
}
.bx.bx-horizontal-line:before {
  content: "\e90f";
}
.bx.bx-label-none:before {
  content: "\e910";
}
.bx.bx-likert-in-line:before {
  content: "\e911";
}
.bx.bx-progress-bar:before {
  content: "\e912";
}
.bx.bx-open-properties:before {
  content: "\e913";
}
.bx.bx-label-top:before {
  content: "\e914";
}
.bx.bx-screen-maximize-thin:before {
  content: "\e915";
}
.bx.bx-paging:before {
  content: "\e916";
}
.bx.bx-label-right:before {
  content: "\e917";
}
.bx.bx-search:before {
  content: "\e918";
}
.bx.bx-push-pin:before {
  content: "\e919";
}
.bx.bx-add-question:before {
  content: "\e91a";
}
.bx.bx-remove-question:before {
  content: "\e91b";
}
.bx.bx-trash-thin:before {
  content: "\e91c";
}
.bx.bx-arrows:before {
  content: "\e91d";
}
.bx.bx-minus:before {
  content: "\e91e";
}
.bx.bx-plus:before {
  content: "\e921";
}
.bx.bx-chevron:before {
  content: "\e922";
}
.bx.bx-check-circle:before {
  content: "\e923";
}
.bx.bx-upload:before {
  content: "\e924";
}
.bx.bx-times-circle:before {
  content: "\e925";
}
.bx.bx-exclamation:before {
  content: "\e926";
}
.bx.bx-caret-down:before {
  content: "\e927";
}
.bx.bx-ban:before {
  content: "\e928";
}
.bx.bx-plus-circle:before {
  content: "\e929";
}
.bx.bx-edit:before {
  content: "\e92a";
}
.bx.bx-asterisk:before {
  content: "\e92b";
}
.bx.bx-times:before {
  content: "\e92c";
}
.bx.bx-spinner:before {
  content: "\e92d";
}
.bx.bx-action-hide-close:before {
  content: "\e92e";
}
.bx.bx-download-arrow:before {
  content: "\e92f";
}
.bx.bx-align-justify:before {
  content: "\e91f";
}
.bx.bx-align-to-left:before {
  content: "\e920";
}
.bx.bx-restart:before {
  content: "\e951";
}
.bx.bx-align-center:before {
  content: "\e96e";
}
.bx.bx-align-to-right:before {
  content: "\e96f";
}
.bx.bx-align-bottom:before {
  content: "\e970";
}
.bx.bx-notifications:before {
  content: "\e971";
}
.bx.bx-forms:before {
  content: "\e972";
}
.bx.bx-align-middle:before {
  content: "\e973";
}
.bx.bx-align-top:before {
  content: "\e974";
}
.bx.bx-list-menu:before {
  content: "\e975";
}
.bx.bx-numbered-list:before {
  content: "\e976";
}
.bx.bx-right-indent:before {
  content: "\e977";
}
.bx.bx-left-indent:before {
  content: "\e978";
}
.bx.bx-insert-object-menu:before {
  content: "\e979";
}
.bx.bx-video:before {
  content: "\e97a";
}
.bx.bx-picture:before {
  content: "\e97b";
}
.bx.bx-link:before {
  content: "\e97c";
}
.bx.bx-file:before {
  content: "\e97d";
}
.bx.bx-undo:before {
  content: "\e97e";
}
.bx.bx-do:before {
  content: "\e97f";
}
.bx.bx-preview-thin:before {
  content: "\e980";
}
.bx.bx-input-choices:before {
  content: "\e981";
}
.bx.bx-single-choice-vertical:before {
  content: "\e982";
}
.bx.bx-single-choice-horizontal:before {
  content: "\e983";
}
.bx.bx-multi-choice-horizontal:before {
  content: "\e984";
}
.bx.bx-multi-choice-vertical:before {
  content: "\e985";
}
.bx.bx-dropdown-list:before {
  content: "\e986";
}
.bx.bx-list-box:before {
  content: "\e987";
}
.bx.bx-input-text:before {
  content: "\e988";
}
.bx.bx-textbox:before {
  content: "\e989";
}
.bx.bx-commentbox:before {
  content: "\e98a";
}
.bx.bx-input-number:before {
  content: "\e98b";
}
.bx.bx-numberbox:before {
  content: "\e98c";
}
.bx.bx-slider:before {
  content: "\e98d";
}
.bx.bx-calendar-thin:before {
  content: "\e98e";
}
.bx.bx-date:before {
  content: "\e98f";
}
.bx.bx-time:before {
  content: "\e990";
}
.bx.bx-input-likert:before {
  content: "\e991";
}
.bx.bx-likert-in-row:before {
  content: "\e992";
}
.bx.bx-input-constant-sum:before {
  content: "\e993";
}
.bx.bx-input-ranking:before {
  content: "\e994";
}
.bx.bx-input-upload:before {
  content: "\e995";
}
.bx.bx-input-document:before {
  content: "\e996";
}
.bx.bx-input-picture:before {
  content: "\e997";
}
.bx.bx-input-video:before {
  content: "\e998";
}
.bx.bx-input-audio:before {
  content: "\e999";
}
.bx.bx-smart-area:before {
  content: "\e99a";
}
.bx.bx-smart-table:before {
  content: "\e99b";
}
.bx.bx-tables-menu:before {
  content: "\e99c";
}
.bx.bx-pagebreak:before {
  content: "\e99d";
}
.bx.bx-show:before {
  content: "\e99e";
}
.bx.bx-action-hide:before {
  content: "\e99f";
}
.bx.bx-randomize:before {
  content: "\e9a0";
}
.bx.bx-action-pipe:before {
  content: "\e9a1";
}
.bx.bx-repeat:before {
  content: "\e9a2";
}
.bx.bx-fork:before {
  content: "\e9a3";
}
.bx.bx-variable:before {
  content: "\e9a4";
}
.bx.bx-settings-menu:before {
  content: "\e9a5";
}
.bx.bx-publish:before {
  content: "\e9a6";
}
.bx.bx-publishing:before {
  content: "\e9a8";
}
.bx.bx-action-ab-testing:before {
  content: "\e9a9";
}
.bx.bx-bold:before {
  content: "\e9ac";
}
.bx.bx-cells-menu:before {
  content: "\e9ad";
}
.bx.bx-duplicate-thin:before {
  content: "\e9b6";
}
.bx.bx-italic:before {
  content: "\e9bc";
}
.bx.bx-line-spacing:before {
  content: "\e9c4";
}
.bx.bx-palette-colors:before {
  content: "\e9c7";
}
.bx.bx-paragraph-menu:before {
  content: "\e9c8";
}
.bx.bx-publish-survey-thin:before {
  content: "\e9ca";
}
.bx.bx-save:before {
  content: "\e9cd";
}
.bx.bx-strikethrough:before {
  content: "\e9d0";
}
.bx.bx-subscript:before {
  content: "\e9d1";
}
.bx.bx-superscript:before {
  content: "\e9d2";
}
.bx.bx-table-of-content:before {
  content: "\e9d3";
}
.bx.bx-test-option:before {
  content: "\e9d4";
}
.bx.bx-text-align-menu:before {
  content: "\e9d5";
}
.bx.bx-text-format:before {
  content: "\e9d6";
}
.bx.bx-underline:before {
  content: "\e9d8";
}
.bx.bx-unordered-list:before {
  content: "\e9d9";
}
