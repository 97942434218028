.PropertiesPanel-Header {
  padding: 20px 10px;
  font-weight: bolder;
  border-bottom: 1px solid #e4e4e4;
}

.PropertiesPanel-Content {
  padding: 20px 10px;
  overflow: auto;
}
