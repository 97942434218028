.PropertiesPanel-FormControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.PropertiesPanel-FormControl label {
  width: 100%;
  font-size: 13px;
}

.PropertiesPanel-FormControl input:not([type="checkbox"]),
.PropertiesPanel-FormControl select {
  width: 100%;
  height: 30px;
}

.PropertiesPanel-FormControl label ~ input,
.PropertiesPanel-FormControl label ~ select {
  margin-top: 4px;
}

.PropertiesPanel-FormControl select[multiple] {
  height: 60px;
}

.PropertiesPanel-FormControl-Multiple-Select {
  width: 100%;
}

.PropertiesPanel-QuestionTitle {
  position: relative;
}

.PropertiesPanel-QuestionTitle .PropertiesPanel-FormControl input {
  width: calc(100% - 35px);
}

.PropertiesPanel-QuestionTitle button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
}
