.ToolbarCustomAreaSizePicker {
  background: white;
  border: 1px solid black;
  padding: 8px;
}

.PageBreakPropertiesPanel textarea {
  width: 100%;
}

.PageBreakPropertiesPanel label > * {
  margin-top: 5px;
}
