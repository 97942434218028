.ProseMirror-width-resizer {
  --width-resizer-color-1: #006edb;
  --width-resizer-color-2: #ffffff;

  position: relative;
}

.ProseMirror-width-resizer width-resizer-handle {
  z-index: 40;
  position: absolute;
  width: 7px;
  height: 22px;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
  right: -4px;
  cursor: ew-resize;
  border-width: 1px;
  border-style: solid;
  border-color: var(--width-resizer-color-1);
  background-color: var(--width-resizer-color-2);
}

.ProseMirror-width-resizer-inline.ProseMirror-focusednode:not(.ProseMirror-inline):not(.ProseMirror-outline-hide)::before {
  left: 1px;
  right: 1px;
}

.ProseMirror-width-resizer:not(.ProseMirror-focusednode)
  > width-resizer-handle {
  display: none;
}

.ProseMirror-width-resizer.ProseMirror-focusednode > width-resizer-handle {
  display: inline-block;
}

.ProseMirror-width-resizer.ProseMirror-focusednode > width-resizer-handle:hover,
.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle.active {
  background-color: var(--width-resizer-color-1);
}

.ProseMirror[contenteditable="false"]
  .ProseMirror-width-resizer
  width-resizer-handle {
  display: none;
}
