.ProseMirror ul {
  margin: 5px 0;
  padding-left: 20px;
  list-style: none;
}

.ProseMirror ul li {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.ProseMirror ul li[data-alignment="left"] {
  justify-content: flex-start;
}

.ProseMirror ul li[data-alignment="center"] {
  justify-content: center;
}

.ProseMirror ul li[data-alignment="right"] {
  justify-content: flex-end;
}

.ProseMirror ul li[data-alignment="justify"] {
  justify-content: flex-start;
}

.ProseMirror ul li list-item-content {
  min-width: 1px;
}

.ProseMirror ul li list-item-marker {
  flex: none;
  -webkit-user-select: none;
  user-select: none;
  font-weight: bold;
}

.ProseMirror ul li list-item-marker::before {
  content: "\2022";
  padding-right: 0.35em;
  font-weight: var(--list-item-font-weight);
  font-style: var(--list-item-font-style);
  text-decoration: var(--list-item-text-decoration);
  color: var(--list-item-color);
  background-color: var(--list-item-background-color);
  font-size: var(--list-item-font-size);
  font-family: var(--list-item-font-family);
  display: inline-block;
  transform: scale(1.3);
}
