.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #fff 0, #f0f0f0 100%);
}

.Toolbar {
  flex: none;
}

.Editor {
  flex: 1 1 auto;
}
