.ProseMirror input-file {
  --input-file-color-1: rgba(240, 240, 240, 1);
  --input-file-color-2: rgba(85, 85, 85, 1);
  --input-file-color-3: rgba(204, 204, 204, 1);

  --input-file-rounded-style-border-radius: 4px;

  --input-file-container-background-color: var(--input-file-color-1);
  --input-file-container-border-color: var(--input-file-color-3);
  --input-file-container-color: var(--input-file-color-2);
}

.ProseMirror[data-palette-type="light"] input-file {
  --input-file-container-background-color: var(--color-scheme-theme-1);
  --input-file-container-border-color: var(--color-scheme-theme-3);
  --input-file-container-color: var(--color-scheme-theme-5);
}

.ProseMirror[data-palette-type="dark"] input-file {
  --input-file-container-background-color: var(--color-scheme-primary-1);
  --input-file-container-border-color: var(--color-scheme-theme-3);
  --input-file-container-color: var(--color-scheme-theme-1);
}

.ProseMirror input-file {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
}

.ProseMirror input-file[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror input-file[data-alignment="left"] {
  margin-right: auto;
}

.ProseMirror input-file[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror input-file .input-container {
  background: var(--input-file-container-background-color);
  border: 1px solid var(--input-file-container-border-color);
  color: var(--input-file-container-color);
  font-size: 15px;
  height: 100%;
  line-height: 1.4;
  margin: 0;
  max-width: 100%;
  min-height: 120px;
  padding: 10px;
  text-align: center;
  vertical-align: bottom;
}

.ProseMirror[data-input-style="rounded"] input-file .input-container {
  border-radius: var(--input-file-rounded-style-border-radius);
}

.ProseMirror[data-input-style="simple"] input-file .input-container {
  background: transparent;
  border-style: dashed;
}

.ProseMirror
  custom-grid
  th
  input-file.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle,
.ProseMirror
  custom-grid
  td
  input-file.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle {
  display: none;
}

.ProseMirror custom-grid td input-file {
  width: 100% !important;
}
