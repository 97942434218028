.ProseMirror input-text {
  --input-text-color-1: rgba(255, 255, 255, 1);
  --input-text-color-2: rgba(85, 85, 85, 1);
  --input-text-color-3: rgba(204, 204, 204, 1);
  --input-text-color-4: rgba(240, 240, 240, 1);

  --input-text-rounded-style-border-radius: 4px;

  --input-text-container-background-color: var(--input-text-color-1);
  --input-text-container-border-color: var(--input-text-color-3);
  --input-text-container-border-accent-color: var(--input-text-color-4);
  --input-text-container-color: var(--input-text-color-2);
  --input-text-default-value-color: var(--input-text-color-2);
  --input-text-watermark-color: var(--input-text-color-2);
}

.ProseMirror[data-palette-type="light"] input-text {
  --input-text-container-background-color: var(--color-scheme-primary-1);
  --input-text-container-border-color: var(--color-scheme-theme-3);
  --input-text-container-border-accent-color: var(--color-scheme-theme-1);
  --input-text-container-color: var(--color-scheme-theme-5);
  --input-text-default-value-color: var(--color-scheme-theme-5);
  --input-text-watermark-color: var(--color-scheme-theme-5);
}

.ProseMirror[data-palette-type="dark"] input-text {
  --input-text-container-background-color: var(--color-scheme-primary-1);
  --input-text-container-border-color: var(--color-scheme-theme-3);
  --input-text-container-border-accent-color: var(--color-scheme-theme-2);
  --input-text-container-color: var(--color-scheme-theme-5);
  --input-text-default-value-color: var(--color-scheme-theme-1);
  --input-text-watermark-color: var(--color-scheme-theme-1);
}

.ProseMirror input-text {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
}

.ProseMirror input-text[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror input-text[data-alignment="left"] {
  margin-right: auto;
}

.ProseMirror input-text[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror input-text .input-container {
  background-color: var(--input-text-container-background-color);
  border: 1px solid var(--input-text-container-border-color);
  color: var(--input-text-container-color);
  font-size: 15px;
  height: 100%;
  max-width: 100%;
  min-height: 34px;
  overflow: hidden;
  padding: 4px;
  line-height: 1.65;
}

.ProseMirror input-text[data-control-type="textbox"] .input-container {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ProseMirror input-text[data-control-type="textarea"] .input-container {
  white-space: break-spaces;
  word-break: break-all;
  overflow-y: auto;
}

.ProseMirror input-text .input-container.watermark {
  color: var(--input-text-watermark-color);
}

.ProseMirror input-text .input-container.default-value {
  color: var(--input-text-default-value-color);
}

.ProseMirror[data-input-style="rounded"] input-text .input-container {
  border-radius: var(--input-text-rounded-style-border-radius);
}

.ProseMirror[data-input-style="simple"] input-text .input-container {
  background: transparent;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.ProseMirror[data-input-style="simple"]
  input-text[data-control-type="textarea"]
  .input-container {
  border-top-color: var(--input-text-container-border-accent-color);
  border-left-color: var(--input-text-container-border-accent-color);
  border-right-color: var(--input-text-container-border-accent-color);
}

.ProseMirror
  custom-grid
  th
  input-text.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle,
.ProseMirror
  custom-grid
  td
  input-text.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle {
  display: none;
}

.ProseMirror custom-grid td input-text {
  width: 100% !important;
}
