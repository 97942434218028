.ProseMirror custom-grid {
  --custom-grid-color-1: rgba(51, 51, 51, 1);
  --custom-grid-color-2: rgb(204, 204, 204, 1);
  --custom-grid-color-3: rgb(240, 240, 240, 1);
  --custom-grid-color-4: rgba(255, 255, 255, 1);
  --custom-grid-color-5: #006edb;
  --custom-grid-color-6: rgba(85, 85, 85, 1);

  --custom-grid-color: var(--custom-grid-color-1);
  --custom-grid-border-color: var(--custom-grid-color-2);
  --custom-grid-header-cell-background-color: var(--custom-grid-color-3);
  --custom-grid-cell-background-color: var(--custom-grid-color-4);

  --custom-grid-reapeat-last-row-color: var(--custom-grid-color-6);
  --custom-grid-reapeat-last-row-border-color: var(--custom-grid-color-6);
  --custom-grid-reapeat-last-row-background-color: var(--custom-grid-color-3);

  --custom-grid-selected-background-color: rgba(208, 229, 255, 0.3); /*#d0e5ff*/
  --custom-grid-selected-border-color: var(--custom-grid-color-5);
  --custom-grid-controls-background-color: #fafafd;
  --custom-grid-controls-border-color: #c7c7cc;
  --custom-grid-controls-active-background-color: var(--custom-grid-color-5);
  --custom-grid-controls-active-border-color: var(--custom-grid-color-5);
  --custom-grid-input-validation-message-border-color: var(
    --custom-grid-color-6
  );
  --custom-grid-input-validation-message-background-color: var(
    --custom-grid-color-6
  );
  --custom-grid-input-validation-message-background-color: var(
    --custom-grid-color-3
  );
}

/*Themes and skins*/
.ProseMirror[data-palette-type="light"] custom-grid {
  --custom-grid-color: var(--color-scheme-primary-5);
  --custom-grid-border-color: var(--color-scheme-theme-3);
  --custom-grid-header-cell-background-color: var(--color-scheme-theme-1);
  --custom-grid-cell-background-color: var(--color-scheme-primary-1);
  --custom-grid-reapeat-last-row-color: var(--color-scheme-theme-5);
  --custom-grid-reapeat-last-row-border-color: var(--color-scheme-theme-5);
  --custom-grid-reapeat-last-row-background-color: var(--color-scheme-theme-1);
  --custom-grid-input-validation-message-border-color: var(
    --color-scheme-theme-5
  );
  --custom-grid-input-validation-message-background-color: var(
    --color-scheme-theme-5
  );
  --custom-grid-input-validation-message-color: var(--color-scheme-theme-1);
}

.ProseMirror[data-palette-type="dark"] custom-grid {
  --custom-grid-color: var(--color-scheme-primary-5);
  --custom-grid-border-color: var(--color-scheme-theme-3);
  --custom-grid-header-cell-background-color: var(--color-scheme-theme-2);
  --custom-grid-cell-background-color: var(--color-scheme-primary-1);
  --custom-grid-reapeat-last-row-color: var(--color-scheme-theme-1);
  --custom-grid-reapeat-last-row-border-color: var(--color-scheme-theme-5);
  --custom-grid-reapeat-last-row-background-color: var(--color-scheme-theme-4);
  --custom-grid-input-validation-message-border-color: var(
    --color-scheme-theme-5
  );
  --custom-grid-input-validation-message-background-color: var(
    --color-scheme-theme-5
  );
  --custom-grid-input-validation-message-color: var(--color-scheme-theme-1);
}

.ProseMirror custom-grid {
  display: block;
}

.ProseMirror custom-grid[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror custom-grid[data-alignment="left"] {
  margin-right: auto;
}

.ProseMirror custom-grid[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror custom-grid.ProseMirror-focusednode::before {
  z-index: 21 !important;
}

.ProseMirror custom-grid.ProseMirror-selectednode::before {
  background-color: var(--custom-grid-selected-background-color);
}

.ProseMirror custom-grid table {
  position: relative;
  width: 100%;
  border: 0;
  border-collapse: collapse;
  empty-cells: show;
}

.ProseMirror custom-grid + custom-grid table,
.ProseMirror custom-grid + .ProseMirror-gapcursor + custom-grid table {
  margin-top: -1px;
}

.ProseMirror custom-grid th,
.ProseMirror custom-grid td {
  min-width: 34px;
  position: relative;
  font-weight: normal;
  padding: 10px 16px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: var(--custom-grid-border-color);
  border-right-color: var(--custom-grid-border-color);
  border-bottom-color: var(--custom-grid-border-color);
  border-left-color: var(--custom-grid-border-color);
  color: var(--custom-grid-cell-color);
  vertical-align: top;
  min-height: 1px;
  height: 2px;
}

@-moz-document url-prefix() {
  .ProseMirror custom-grid th,
  .ProseMirror custom-grid td {
    background-clip: padding-box;
  }
}

.ProseMirror custom-grid th {
  background-color: var(--custom-grid-header-cell-background-color);
}

.ProseMirror custom-grid td {
  background-color: var(--custom-grid-cell-background-color);
}

.ProseMirror custom-grid .ProseMirror-table-control {
  position: absolute;
  left: -17px;
  top: -17px;
  height: 16px;
  width: 16px;
  background-color: var(--custom-grid-controls-background-color);
  border: 1px solid var(--custom-grid-controls-border-color);
  border-right: none;
  border-bottom: none;
  z-index: 20;
  cursor: pointer;
}

.ProseMirror custom-grid .ProseMirror-table-column-control {
  position: absolute;
  left: -1px;
  right: -1px;
  top: -17px;
  height: 16px;
  background-color: var(--custom-grid-controls-background-color);
  border: 1px solid var(--custom-grid-controls-border-color);
  border-bottom: none;
  z-index: 20;
  cursor: pointer;
}

.ProseMirror custom-grid .ProseMirror-table-row-control {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -17px;
  width: 16px;
  background-color: var(--custom-grid-controls-background-color);
  border: 1px solid var(--custom-grid-controls-border-color);
  border-right: none;
  z-index: 20;
  cursor: pointer;
}

.ProseMirror custom-grid .ProseMirror-table-control.active,
.ProseMirror custom-grid .ProseMirror-table-column-control.active,
.ProseMirror custom-grid .ProseMirror-table-row-control.active {
  background-color: var(--custom-grid-controls-active-background-color);
  border: 1px solid var(--custom-grid-controls-active-border-color);
  z-index: 21;
}

.ProseMirror custom-grid .column-resize-handle {
  position: absolute;
  right: -2px;
  top: -1px;
  bottom: -1px;
  width: 3px;
  z-index: 22;
  background-color: var(--custom-grid-selected-border-color);
  pointer-events: none;
}

.ProseMirror custom-grid td:last-child .column-resize-handle,
.ProseMirror custom-grid th:last-child .column-resize-handle {
  right: -1px;
  width: 2px;
}

.ProseMirror.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror custom-grid .selectedCell::after {
  position: absolute;
  content: "";
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  pointer-events: none;
  background-color: var(--custom-grid-selected-background-color);
  border: 1px solid var(--custom-grid-selected-border-color);
}

.ProseMirror
  custom-grid[data-last-row-manual-repeat="true"]
  tr:nth-last-child(2)
  td:last-child::before {
  content: "+";
  position: absolute;
  width: 25px;
  height: 25px;
  border-width: 1px;
  border-style: solid;
  top: 10px;
  right: -32px;
  z-index: 3;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 9px;
  color: var(--custom-grid-reapeat-last-row-color);
  background-color: var(--custom-grid-reapeat-last-row-background-color);
  border-color: var(--custom-grid-reapeat-last-row-border-color);
}

.ProseMirror custom-grid > table > tbody > tr > * {
  border-bottom-style: dotted;
  border-top-style: dotted;
  border-left-style: dotted;
  border-right-style: dotted;
}

/* bottom border */
.ProseMirror
  custom-grid[data-border-bottom="true"][data-show-footer="false"]
  > table
  > tbody
  > tr:nth-last-child(2)
  > *,
.ProseMirror
  custom-grid[data-border-bottom="true"][data-show-footer="true"]
  > table
  > tbody
  > tr:last-child
  > * {
  border-bottom-style: solid;
}

/* top border */
.ProseMirror
  custom-grid[data-border-top="true"][data-show-header="false"]
  > table
  > tbody
  > tr:nth-child(2)
  > *,
.ProseMirror
  custom-grid[data-border-top="true"][data-show-header="true"]
  > table
  > tbody
  > tr:first-child
  > * {
  border-top-style: solid;
}

/* left border */
.ProseMirror
  custom-grid[data-border-left="true"]
  > table
  > tbody
  > tr
  > *:first-child {
  border-left-style: solid;
}

/* right border */
.ProseMirror
  custom-grid[data-border-right="true"]
  > table
  > tbody
  > tr
  > *:last-child {
  border-right-style: solid;
}

/* inside horizontal border */
.ProseMirror
  custom-grid[data-border-inside-horizontal="true"][data-show-footer="false"]
  > table
  > tbody
  > tr:nth-last-child(2)
  > *,
.ProseMirror
  custom-grid[data-border-inside-horizontal="true"][data-show-footer="true"]
  > table
  > tbody
  > tr:last-child
  > * {
  border-top-style: solid;
}
.ProseMirror
  custom-grid[data-border-inside-horizontal="true"][data-show-header="false"]
  > table
  > tbody
  > tr:nth-child(2)
  > *,
.ProseMirror
  custom-grid[data-border-inside-horizontal="true"][data-show-header="true"]
  > table
  > tbody
  > tr:first-child
  > * {
  border-bottom-style: solid;
}
.ProseMirror
  custom-grid[data-border-inside-horizontal="true"][data-show-footer="false"][data-show-header="false"]
  > table
  > tbody
  > tr:not(:nth-last-child(2)):not(:nth-child(2))
  > *,
.ProseMirror
  custom-grid[data-border-inside-horizontal="true"][data-show-footer="false"][data-show-header="true"]
  > table
  > tbody
  > tr:not(:nth-last-child(2)):not(:first-child)
  > *,
.ProseMirror
  custom-grid[data-border-inside-horizontal="true"][data-show-footer="true"][data-show-header="false"]
  > table
  > tbody
  > tr:not(:last-child):not(:nth-child(2))
  > *,
.ProseMirror
  custom-grid[data-border-inside-horizontal="true"][data-show-footer="true"][data-show-header="true"]
  > table
  > tbody
  > tr:not(:last-child):not(:first-child)
  > * {
  border-top-style: solid;
  border-bottom-style: solid;
}

/* inside vertical border */
.ProseMirror
  custom-grid[data-border-inside-vertical="true"]
  > table
  > tbody
  > tr
  > *:first-child {
  border-right-style: solid;
}
.ProseMirror
  custom-grid[data-border-inside-vertical="true"]
  > table
  > tbody
  > tr
  > *:last-child {
  border-left-style: solid;
}
.ProseMirror
  custom-grid[data-border-inside-vertical="true"]
  > table
  > tbody
  > tr
  > *:not(:first-child):not(:last-child) {
  border-right-style: solid;
  border-left-style: solid;
}

.ProseMirror custom-grid[data-show-header="false"] [data-section="headers"] {
  display: none;
}

.ProseMirror custom-grid[data-show-footer="false"] [data-section="footers"] {
  display: none;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-row-actions
  .ProseMirror-dropdown-toggle {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 21;
  background-color: #006edb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-row-actions
  .ProseMirror-dropdown-toggle
  .triangle {
  color: #ffffff;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-toggle {
  width: 24px;
  height: 16px;
  position: absolute;
  right: 0px;
  top: -17px;
  z-index: 21;
  background-color: #006edb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-toggle
  .triangle {
  color: #ffffff;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-row-actions
  .ProseMirror-dropdown-content,
.ProseMirror
  custom-grid
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 23;
  padding: 10px 0;
  background: #ffffff;
  border: 1px solid #eeeef1;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  font-size: 0.75rem;
  overflow: auto;
  width: auto;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-row-actions
  .ProseMirror-dropdown-content {
  right: 2px;
  top: 26px;
}

.ProseMirror
  custom-grid
  th:not(:first-child)
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-content,
.ProseMirror
  custom-grid
  td:not(:first-child)
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-content {
  right: 2px;
  top: 2px;
}

.ProseMirror
  custom-grid
  th:first-child
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-toggle,
.ProseMirror
  custom-grid
  td:first-child
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-toggle {
  left: 0px;
}

.ProseMirror
  custom-grid
  th:first-child
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-content,
.ProseMirror
  custom-grid
  td:first-child
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-content {
  left: 2px;
  top: 2px;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-row-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item,
.ProseMirror
  custom-grid
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item {
  font-size: 0.75rem;
  color: #5c6670;
  padding: 7px 10px;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-row-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item:disabled,
.ProseMirror
  custom-grid
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item:disabled {
  opacity: 0.3;
  cursor: default;
}

.ProseMirror
  custom-grid
  .ProseMirror-table-row-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item:not(:disabled):hover,
.ProseMirror
  custom-grid
  .ProseMirror-table-column-actions
  .ProseMirror-dropdown-content
  .ProseMirror-dropdown-item:not(:disabled):hover {
  color: #0b37aa;
  background: #f2f8ff;
}

.ProseMirror[data-input-style="rounded"]
  custom-grid[data-last-row-manual-repeat="true"]
  tr:nth-last-child(2)
  td:last-child::before {
  border-radius: 4px;
}
