.Toolbar {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.Toolbar .Toolbar-row {
  display: flex;
  flex-direction: row;
}

.Toolbar .Toolbar-row ~ .Toolbar-row {
  margin-top: 10px;
}

.Toolbar .Toolbar-row > * + * {
  margin-left: 10px;
}
