@import './bx-font-icons/style.css';

.bx {
  display: inline-block;
  font: normal normal normal 14px/1 'blueX';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes bx-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.bx.bx-fw {
  width: 1.28571429em;
  text-align: center;
}

.bx.bx-pulse {
  -webkit-animation: bx-spin 1s infinite steps(32);
  animation: bx-spin 1s infinite steps(32);
}
