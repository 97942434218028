.ProseMirror {
  --report-color-1: rgba(240, 247, 255, 1);
  --report-color-2: rgba(25, 36, 52, 1);
  --report-color-3: rgba(0, 110, 219, 1);
}

.ProseMirror[data-palette-type="light"] .ProseMirror-input-linked {
  --report-overlay-background: var(--report-color-1);
  --report-linked-background: var(--report-color-1);
  --report-overlay-border: var(--report-color-3);
  --report-linked-outline: var(--report-color-3);
}

.ProseMirror[data-palette-type="dark"] .ProseMirror-input-linked {
  --report-overlay-background: var(--report-color-2);
  --report-linked-background: var(--report-color-2);
  --report-overlay-border: var(--report-color-3);
  --report-linked-outline: var(--report-color-3);
}

.ProseMirror[data-palette-type="light"] .ProseMirror-report-overlay-trailing {
  --report-overlay-background: var(--report-color-1);
  --report-linked-background: var(--report-color-2);
  --report-overlay-border: var(--report-color-3);
  --report-linked-outline: var(--report-color-3);
}

.ProseMirror[data-palette-type="dark"] .ProseMirror-report-overlay-trailing {
  --report-overlay-background: var(--report-color-2);
  --report-linked-background: var(--report-color-2);
  --report-overlay-border: var(--report-color-3);
  --report-linked-outline: var(--report-color-3);
}

.ProseMirror .ProseMirror-input-linked {
  outline: 2px dotted var(--report-linked-outline);
}

.ProseMirror .ProseMirror-report-overlay-container {
  position: absolute;
  display: flex;
}

.ProseMirror .ProseMirror-report-overlay-middle {
  flex: 1 1 0;
}

.ProseMirror .ProseMirror-report-overlay-trailing {
  border: 1px solid var(--report-overlay-border);
  background-color: var(--report-overlay-background);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 5;
  position: relative;
  top: -1px;
}

.ProseMirror .ProseMirror-report-overlay-trailing .bx-action-close {
  color: var(--report-overlay-border);
  background-color: var(--report-overlay-background);
  font-size: 21px;
  cursor: pointer;
}
