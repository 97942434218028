.FileDefinition {
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  width: 200px;
  font-size: 13px;
}

.FileDefinition input {
  width: 100%;
}
