.ProseMirror text-variable {
  display: inline;
}

.ProseMirror link-variable {
  --link-variable-color: #006edb;

  display: inline;
  color: var(--link-variable-color);
}

.ProseMirror question-variable {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
}

.ProseMirror question-variable question-not-found {
  display: block;
  padding: 8px;
  text-align: center;
  background-color: #ececec;
  color: #be0611;
  font-size: 15px;
  line-height: 1.4;
}

.ProseMirror question-variable > .ProseMirror {
  padding: 0;
  caret-color: transparent;
}

.ProseMirror question-variable > .ProseMirror *::selection {
  background: transparent;
}

.ProseMirror question-variable input-scale input-scale-values,
.ProseMirror question-variable input-choice input-choice-values {
  max-height: none !important;
}

.ProseMirror .variable-picker-dropdown {
  --variable-dropdown-toggle-background: #006edb;
  --variable-dropdown-toggle-color: #ffffff;

  position: absolute;
  display: inline-flex;
  flex-direction: column;
  margin-left: 4px;
  z-index: 41;
}

.ProseMirror .variable-picker-dropdown .variable-picker-dropdown-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: var(--variable-dropdown-toggle-background);
}

.ProseMirror
  .variable-picker-dropdown
  .variable-picker-dropdown-toggle
  .triangle {
  color: var(--variable-dropdown-toggle-color);
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}

.ProseMirror .variable-picker-dropdown .variable-picker-dropdown-content {
  position: absolute;
}

.ProseMirror
  .variable-picker-dropdown[data-dropdown-position="top-right"]
  .variable-picker-dropdown-content {
  bottom: 28px;
  left: 0;
}

.ProseMirror
  .variable-picker-dropdown[data-dropdown-position="top-left"]
  .variable-picker-dropdown-content {
  bottom: 28px;
  right: 0;
}

.ProseMirror
  .variable-picker-dropdown[data-dropdown-position="bottom-right"]
  .variable-picker-dropdown-content {
  top: 28px;
  left: 0;
}

.ProseMirror
  .variable-picker-dropdown[data-dropdown-position="bottom-left"]
  .variable-picker-dropdown-content {
  top: 28px;
  right: 0;
}
