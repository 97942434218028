@import "./plugins/gap-cursor.css";
@import "./plugins/width-resizer.css";
@import "./plugins/report-outline.css";
@import "./plugins/validation-messages.css";
@import "./plugins/action-controls.css";

.ProseMirror {
  --document-color: #000000;
  --document-background-color: #ffffff;
  --document-element-outline: #006edb;
  --document-scrollbar-track-color: #dedede;
  --document-scrollbar-thumb-color: #aaaaaa;
  --document-unfocused-selection-color: rgba(102, 102, 102, 0.3);
  --document-focused-selection-color: rgba(172, 206, 247, 0.3);
  --document-padding-top: 30px;
  --document-padding-bottom: 30px;
  --document-padding-left: 35px;
  --document-padding-right: 35px;
}

.ProseMirror[data-palette-type="light"] {
  --document-color: var(--color-scheme-primary-5);
  --document-background-color: var(--color-scheme-primary-1);
  --document-scrollbar-track-color: #dedede;
  --document-scrollbar-thumb-color: #aaaaaa;
  --document-unfocused-selection-color: rgba(102, 102, 102, 0.3);
  --document-focused-selection-color: rgba(172, 206, 247, 0.3);
}

.ProseMirror[data-palette-type="dark"] {
  --document-color: var(--color-scheme-primary-5);
  --document-background-color: var(--color-scheme-primary-6);
  --document-scrollbar-track-color: var(--color-scheme-theme-2);
  --document-scrollbar-thumb-color: var(--color-scheme-primary-5);
  --document-unfocused-selection-color: rgba(102, 102, 102, 0.3);
  --document-focused-selection-color: rgba(115, 163, 204, 0.3);
}

.ProseMirror {
  position: relative;
  font-size: var(--document-text-size);
  font-family: var(--document-text-font);
  text-align: var(--document-text-align);
  padding-top: var(--document-padding-top);
  padding-bottom: var(--document-padding-bottom);
  padding-left: var(--document-padding-left);
  padding-right: var(--document-padding-right);
  color: var(--document-color);
  background-color: var(--document-background-color);
}

.ProseMirror::-webkit-scrollbar,
.ProseMirror *::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}

.ProseMirror::-webkit-scrollbar-track,
.ProseMirror *::-webkit-scrollbar-track {
  border: 7px solid transparent;
  background: var(--document-scrollbar-track-color);
  border-radius: 0px;
  background-clip: padding-box;
}

.ProseMirror::-webkit-scrollbar-thumb,
.ProseMirror *::-webkit-scrollbar-thumb {
  border: 7px solid transparent;
  background: var(--document-scrollbar-thumb-color);
  border-radius: 10px;
  background-clip: padding-box;
}

.ProseMirror::-webkit-scrollbar-corner,
.ProseMirror *::-webkit-scrollbar-corner {
  background: transparent;
}

.ProseMirror,
.ProseMirror::before,
.ProseMirror::after,
.ProseMirror *,
.ProseMirror *::before,
.ProseMirror *::after {
  box-sizing: border-box;
}

.ProseMirror {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
}

.ProseMirror-disable-selection {
  user-select: none;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror pre {
  white-space: pre-wrap;
}

/* Protect against generic img rules */
img.ProseMirror-separator {
  display: inline !important;
  border: none !important;
  margin: 0 !important;
}

.ProseMirror:not(.ProseMirror-hideselection) *::selection {
  background-color: var(--document-unfocused-selection-color);
}

.ProseMirror.ProseMirror-focused:not(.ProseMirror-hideselection) *::selection {
  background-color: var(--document-focused-selection-color);
}

.ProseMirror:not(.ProseMirror-hideselection) .ProseMirror-selection {
  position: relative;
}

.ProseMirror:not(.ProseMirror-hideselection)
  .ProseMirror-selection
  *::selection {
  background: transparent;
}

.ProseMirror:not(.ProseMirror-hideselection) .ProseMirror-selection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--document-unfocused-selection-color);
}

.ProseMirror.ProseMirror-focused:not(.ProseMirror-hideselection)
  .ProseMirror-selection {
  position: relative;
}

.ProseMirror.ProseMirror-focused:not(.ProseMirror-hideselection)
  .ProseMirror-selection::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--document-focused-selection-color);
}

.ProseMirror-hideselection *::selection {
  background: transparent;
}

.ProseMirror-hideselection {
  caret-color: transparent;
}

.ProseMirror-focusednode:not(.ProseMirror-inline):not(.ProseMirror-outline-hide) {
  position: relative;
}

.ProseMirror-focusednode.ProseMirror-inline:not(.ProseMirror-outline-hide) {
  outline: 2px solid var(--document-element-outline);
}

.ProseMirror-focusednode:not(.ProseMirror-inline):not(.ProseMirror-outline-hide)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 2px solid var(--document-element-outline);
  z-index: 1;
  pointer-events: none;
}

.ProseMirror [draggable="true"] {
  cursor: grab;
  user-select: auto;
}

.ProseMirror [draggable="true"]:active {
  cursor: grabbing;
}

.ProseMirror p {
  margin: 0;
}

.ProseMirror button {
  color: inherit;
  background-color: inherit;
  font: inherit;
  letter-spacing: inherit;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
