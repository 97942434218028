.InsertVideo {
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  width: 200px;
  font-size: 13px;
}

.InsertVideo input {
  width: 100%;
}

.VideoPropertiesPanel {
  width: 100%;
  font-size: 13px;
}

.VideoPropertiesPanel label,
.VideoPropertiesPanel input {
  width: 100%;
}
