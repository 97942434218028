.ToolbarDropdown {
  display: inline-block;
  position: relative;
}

.ToolbarDropdown-button {
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  border: 1px solid black;
  border-radius: 0;
  background-color: white;
  margin: 0;
  padding: 4px 8px;
  white-space: nowrap;
  height: 32px;
  min-width: 32px;
  color: black;
}

.ToolbarDropdown-button.ToolbarDropdown-style-light {
  color: black;
}

.ToolbarDropdown-button.ToolbarDropdown-style-dark {
  color: white;
}

.ToolbarDropdown-button:disabled {
  color: #cccccc;
}

.ToolbarDropdown-button:not(:disabled).active {
  background-color: #006edb;
  color: #ffffff;
}

.ToolbarDropdown-button:disabled {
  pointer-events: none;
}

.ToolbarDropdown-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 50;
}

.ToolbarDropdown-content .ToolbarButton {
  margin: 0;
}

.ToolbarDropdown .ToolbarButton + .ToolbarButton {
  margin-left: 0;
}
