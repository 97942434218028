.ProseMirror action-controls {
  --action-control-button-background: #c1e0ff;
  --action-control-button-color: #555555;
  --action-control-button-active-background: #006edb;
  --action-control-button-active-color: #ffffff;
  position: absolute;
  top: -2px;
  right: -31px;
  display: none;
  pointer-events: none;
  z-index: 1;
}

.ProseMirror .ProseMirror-focusednode > action-controls {
  display: flex;
  flex-direction: column;
  pointer-events: initial;
}

.ProseMirror action-control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  background: var(--action-control-button-background);
  color: var(--action-control-button-color);
}

.ProseMirror action-control-button.active {
  background: var(--action-control-button-active-background);
  color: var(--action-control-button-active-color);
}

.ProseMirror action-controls action-control-button ~ action-control-button {
  margin-top: 3px;
}

.ProseMirror.ProseMirror-readonly action-controls {
  display: none;
}
