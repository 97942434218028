.ProseMirror input-number {
  --input-number-color-1: rgba(204, 204, 204, 1);
  --input-number-color-2: rgba(112, 112, 112, 1);
  --input-number-color-3: rgba(85, 85, 85, 1);
  --input-number-color-4: rgba(255, 255, 255, 1);
  --input-number-color-5: rgba(240, 240, 240, 1);
  --input-number-color-6: rgba(51, 153, 255, 1);
  --input-number-color-7: rgba(51, 51, 51, 1);

  --input-number-rounded-style-border-radius: 4px;
  --input-number-rounded-style-slider-border-radius: 10px;

  --input-number-numberbox-container-background-color: var(
    --input-number-color-4
  );
  --input-number-numberbox-container-border-color: var(--input-number-color-1);
  --input-number-numberbox-container-color: var(--input-number-color-3);
  --input-number-numberbox-container-watermark-color: var(
    --input-number-color-2
  );
  --input-number-numberbox-container-default-value-color: var(
    --input-number-color-3
  );
  --input-number-numberbox-button-background-color: var(--input-number-color-5);
  --input-number-numberbox-button-border-color: var(--input-number-color-1);
  --input-number-numberbox-button-color: var(--input-number-color-3);

  --input-number-slider-limit-container-color: var(--input-number-color-7);
  --input-number-slider-bar-background-color: var(--input-number-color-5);
  --input-number-slider-bar-border-color: var(--input-number-color-1);
  --input-number-slider-numberbox-slider-progress-background-color: var(
    --input-number-color-2
  );
  --input-number-slider-numberbox-slider-handlebar-background-color: var(
    --input-number-color-2
  );
  --input-number-slider-numberbox-slider-handlebar-border-color: var(
    --input-number-color-4
  );
  --input-number-slider-default-value-color: var(--input-number-color-7);
  --input-number-slider-numberbox-slider-progress-after-border-color: var(
    --input-number-color-6
  );
  --input-number-slider-numberbox-slider-progress-handlebar-border-color: var(
    --input-number-color-6
  );
}

.ProseMirror[data-palette-type="light"] input-number {
  --input-number-numberbox-container-background-color: var(
    --color-scheme-primary-1
  );
  --input-number-numberbox-container-border-color: var(--color-scheme-theme-3);
  --input-number-numberbox-container-watermark-color: var(
    --color-scheme-theme-4
  );
  --input-number-numberbox-container-default-value-color: var(
    --color-scheme-theme-5
  );
  --input-number-numberbox-button-background-color: var(--color-scheme-theme-1);
  --input-number-numberbox-button-border-color: var(--color-scheme-theme-3);
  --input-number-numberbox-button-color: var(--color-scheme-theme-5);

  --input-number-slider-limit-container-color: var(--color-scheme-primary-5);
  --input-number-slider-bar-background-color: var(--color-scheme-theme-1);
  --input-number-slider-bar-border-color: var(--color-scheme-theme-3);
  --input-number-slider-numberbox-slider-progress-background-color: var(
    --color-scheme-theme-4
  );
  --input-number-slider-numberbox-slider-handlebar-background-color: var(
    --color-scheme-theme-4
  );
  --input-number-slider-numberbox-slider-handlebar-border-color: var(
    --color-scheme-primary-1
  );
  --input-number-slider-default-value-color: var(--color-scheme-primary-5);
}

.ProseMirror[data-palette-type="dark"] input-number {
  --input-number-numberbox-container-background-color: var(
    --color-scheme-primary-1
  );
  --input-number-numberbox-container-border-color: var(--color-scheme-theme-3);
  --input-number-numberbox-container-watermark-color: var(
    --color-scheme-theme-4
  );
  --input-number-numberbox-container-default-value-color: var(
    --color-scheme-theme-1
  );
  --input-number-numberbox-button-background-color: var(--color-scheme-theme-5);
  --input-number-numberbox-button-border-color: var(--color-scheme-theme-3);
  --input-number-numberbox-button-color: var(--color-scheme-theme-1);

  --input-number-slider-limit-container-color: var(--color-scheme-primary-5);
  --input-number-slider-bar-background-color: var(--color-scheme-primary-1);
  --input-number-slider-bar-border-color: var(--color-scheme-theme-3);
  --input-number-slider-numberbox-slider-progress-background-color: var(
    --color-scheme-theme-4
  );
  --input-number-slider-numberbox-slider-handlebar-background-color: var(
    --color-scheme-theme-4
  );
  --input-number-slider-numberbox-slider-handlebar-border-color: var(
    --color-scheme-primary-6
  );
  --input-number-slider-default-value-color: var(--color-scheme-primary-5);
}

.ProseMirror input-number {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: grab;
}

.ProseMirror input-number[data-alignment="center"] {
  margin-left: auto;
  margin-right: auto;
}

.ProseMirror input-number[data-alignment="left"] {
  margin-right: auto;
}

.ProseMirror input-number[data-alignment="right"] {
  margin-left: auto;
}

.ProseMirror input-number .numberbox {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--input-number-numberbox-container-border-color);
}

.ProseMirror input-number .numberbox .value {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 5px;
  overflow: hidden;
  color: var(--input-number-numberbox-container-color);
  font-size: 0.85rem;
  background-color: var(--input-number-numberbox-container-background-color);
}

.ProseMirror input-number .numberbox .value.watermark {
  color: var(--input-number-numberbox-container-watermark-color);
}

.ProseMirror input-number .numberbox .value.default-value {
  color: var(--input-number-numberbox-container-default-value-color);
}

.ProseMirror input-number .numberbox .controls {
  display: flex;
}

.ProseMirror input-number .numberbox .controls > div {
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background: var(--input-number-numberbox-button-background-color);
  color: var(--input-number-numberbox-button-color);
  border-left: 1px solid var(--input-number-numberbox-button-border-color);
}

.ProseMirror input-number .numberbox .prefix-suffix {
  align-self: center;
  max-width: 25%;
  background: var(--input-number-numberbox-button-background-color);
  color: var(--input-number-numberbox-button-color);
}

.ProseMirror input-number .numberbox .value .value-number {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProseMirror input-number .numberbox .value .prefix-suffix + .value-number,
.ProseMirror input-number .numberbox .value .value-number + .prefix-suffix {
  margin-left: 4px;
}

.ProseMirror input-number .numberbox .prefix-suffix .truncate {
  padding: 4px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProseMirror input-number .numberbox-slider {
  padding-right: 5px;
}

.ProseMirror input-number .numberbox-slider .bar-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.ProseMirror input-number .numberbox-slider .bar-container.bottom {
  flex-direction: column-reverse;
}

.ProseMirror input-number .numberbox-slider .bar-container.none {
  padding-top: 16px;
}

.ProseMirror input-number .numberbox-slider .limit-container {
  width: 100%;
  color: var(--input-number-slider-limit-container-color);
  position: relative;
  font-size: 15px;
  padding: 8px 0 16px 20px;
  display: flex;
  justify-content: space-between;
}

.ProseMirror input-number .numberbox-slider .limit-container .minimum,
.ProseMirror input-number .numberbox-slider .limit-container .maximum {
  max-width: 40%;
}

.ProseMirror input-number .numberbox-slider .limit-container .minimum {
  text-align: left;
}

.ProseMirror input-number .numberbox-slider .limit-container .maximum {
  text-align: right;
}

.ProseMirror input-number .numberbox-slider .bar {
  height: 8px;
  position: relative;
  border: 1px solid var(--input-number-slider-bar-border-color);
  background: var(--input-number-slider-bar-background-color);
  display: flex;
  margin: 13px 0;
}

.ProseMirror input-number .numberbox-slider .bar .numberbox-slider-progress {
  position: relative;
  display: flex;
  background: var(
    --input-number-slider-numberbox-slider-progress-background-color
  );
  height: 100%;
  max-width: calc(100% - 30px);
}

.ProseMirror
  input-number
  .numberbox-slider
  .bar
  .numberbox-slider-progress
  .handlebar {
  position: absolute;
  content: "";
  display: flex;
  top: -14px;
  right: -19px;
  background: var(
    --input-number-slider-numberbox-slider-handlebar-background-color
  );
  height: 34px;
  width: 20px;
  border: 2px solid
    var(--input-number-slider-numberbox-slider-handlebar-border-color);
}

.ProseMirror
  input-number
  .numberbox-slider
  .bar.not-selected
  .numberbox-slider-progress:after {
  left: -1px;
}

.ProseMirror input-number .numberbox-slider .default-value-container {
  position: relative;
  white-space: nowrap;
  font-size: 15px;
  color: var(--input-number-slider-default-value-color);
  min-height: 40px;
  display: flex;
  align-items: center;
}

.ProseMirror
  input-number
  .numberbox-slider
  .bar-container.none
  .default-value-container {
  display: none;
}

.ProseMirror
  input-number
  .numberbox-slider
  .bar.not-selected
  .default-value-container {
  top: -42px;
  width: 55px;
  left: 0;
  transform: none;
}

.ProseMirror input-number .numberbox-slider .default-value-container p {
  position: relative;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  min-width: 15px;
  text-align: left;
  white-space: normal;
  max-width: 70%;
}

.ProseMirror
  input-number
  .numberbox-slider
  .bar.not-selected
  .default-value-container
  p {
  text-align: left;
}

.ProseMirror input-number .numberbox-slider .threshold-bar {
  width: 20px;
  height: 100%;
  border: 1px solid
    var(--input-number-slider-numberbox-slider-thresholdbar-border-color);
  border-right: none;
}

.ProseMirror
  input-number.ProseMirror-focusednode
  .numberbox-slider
  .numberbox-slider-progress:after {
  border: 2px solid
    var(--input-number-slider-numberbox-slider-progress-after-border-color);
}

.ProseMirror input-number.ProseMirror-focusednode .numberbox-slider .bar,
.ProseMirror
  input-number.ProseMirror-focusednode
  .numberbox-slider
  .bar
  .numberbox-slider-progress
  .handlebar {
  border-color: var(
    --input-number-slider-numberbox-slider-progress-handlebar-border-color
  );
}

.ProseMirror[data-input-style="rounded"] input-number .numberbox {
  border-radius: var(--input-number-rounded-style-border-radius);
}

.ProseMirror[data-input-style="rounded"] input-number .numberbox .value {
  border-radius: var(--input-number-rounded-style-border-radius) 0 0
    var(--input-number-rounded-style-border-radius);
}

.ProseMirror[data-input-style="rounded"]
  input-number
  .numberbox
  .controls
  > div:last-child {
  border-radius: 0 var(--input-number-rounded-style-border-radius)
    var(--input-number-rounded-style-border-radius) 0;
}

.ProseMirror[data-input-style="rounded"] input-number .numberbox-slider .bar {
  border-radius: var(--input-number-rounded-style-border-radius);
}

.ProseMirror[data-input-style="rounded"]
  input-number
  .numberbox-slider
  .bar
  .numberbox-slider-progress
  .handlebar {
  height: 20px;
  top: -7px;
  border-radius: var(--input-number-rounded-style-slider-border-radius);
}

.ProseMirror[data-input-style="simple"] input-number .numberbox {
  border-left: transparent;
  border-top: transparent;
  border-right: transparent;
}

.ProseMirror[data-input-style="simple"] input-number .numberbox .value {
  background: transparent;
}

.ProseMirror[data-input-style="simple"]
  input-number
  .numberbox
  .controls
  > div {
  border-left-color: transparent;
  background: transparent;
}

.ProseMirror[data-input-style="simple"] input-number .numberbox-slider .bar {
  border-radius: var(--input-number-rounded-style-border-radius);
  height: 4px;
}

.ProseMirror[data-input-style="simple"]
  input-number
  .numberbox-slider
  .bar
  .numberbox-slider-progress
  .handlebar {
  height: 20px;
  top: -9px;
  border-radius: var(--input-number-rounded-style-slider-border-radius);
  background-color: var(--color-scheme-primary-1);
  border-color: var(--color-scheme-theme-4);
}

.ProseMirror
  custom-grid
  th
  input-number.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle,
.ProseMirror
  custom-grid
  td
  input-number.ProseMirror-width-resizer.ProseMirror-focusednode
  > width-resizer-handle {
  display: none;
}

.ProseMirror custom-grid td input-number {
  width: 100% !important;
}
