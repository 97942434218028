.VariablePropertiesPanel {
  width: 100%;
  font-size: 13px;
}

.VariablePropertiesPanel label,
.VariablePropertiesPanel input,
.VariablePropertiesPanel select {
  width: 100%;
}

.VariableDropdownPicker {
  display: inline-flex;
  flex-direction: column;
  width: 255px;
  max-height: 300px;
  border: 1px solid #f1f1f1;
  background: white;
  overflow: auto;
  padding: 10px;
}

.VariableDropdownPicker .VariableDropdownPickerItem {
  text-align: left;
  padding: 8px;
  font-size: 12px;
  cursor: pointer;
  color: #000000;
}

.VariableDropdownPicker .VariableDropdownPickerEmpty {
  text-align: left;
  padding: 8px;
  font-size: 12px;
  cursor: default;
  color: #000000;
}
