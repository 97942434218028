@import "../../styles/editor.css";
@import "../../styles/extensions/headings.css";
@import "../../styles/extensions/bar-chart.css";
@import "../../styles/extensions/bullet-list.css";
@import "../../styles/extensions/custom-area.css";
@import "../../styles/extensions/ordered-list.css";
@import "../../styles/extensions/custom-grid.css";
@import "../../styles/extensions/page-break.css";
@import "../../styles/extensions/variable.css";
@import "../../styles/extensions/input-choice.css";
@import "../../styles/extensions/input-text.css";
@import "../../styles/extensions/input-number.css";
@import "../../styles/extensions/input-scale.css";
@import "../../styles/extensions/input-file.css";
@import "../../styles/extensions/input-datetime.css";
@import "../../styles/extensions/input-rank.css";
@import "../../styles/extensions/question-title.css";
@import "../../styles/extensions/constant-sum.css";
@import "../../styles/extensions/placeholder.css";
@import "../../styles/extensions/logic.css";

.ProseMirror {
  line-height: 1.4;
}
